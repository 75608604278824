import { Component } from "react";
import "../../../styles/css/table.scss";
import "../../../App.css";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray } from "../../../Common/Helper";
import Spinner from "../../../Components/Spinner";
import TablePanelInfo from "../../../Components/table/TablePanelInfo"
import { Link } from "react-router-dom";
import { appContext } from "../../../AppContext";



class FacilitySensorDetails extends Component {
  private labels = appContext().labels;

  state = {
    isLoading: false,
    zonesData: [],
    facilityDataSort: { columnName: "", direction: "" },

  };

  render() {
    return (
      <>
            <div className="rightPanel-main-content">
              <div className="table-panel">
                <TablePanelInfo
                  tableHeading={this.labels.HubLabelSensorDetail}
                  iconSrc="/images/Sidebar_Icons/Light_theme/Sensor.svg"
                />
              </div>
            </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(FacilitySensorDetails);
