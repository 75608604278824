import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { store } from "./app/store";
import { Provider } from 'react-redux'
import "./Common/LuxonExtensions";
import { KeyValue } from "./Common/KeyValue";

Array.prototype.insert = function<TItem>(index: number, items: TItem[])
{
    const firstItems = this.filter((value, itemIndex) => itemIndex < index);
    const lastItems = this.filter((value, itemIndex) => itemIndex >= index);
    return [ ...firstItems, ...items, ...lastItems ];
}

Array.prototype.add = function<TItem>(items: TItem[])
{
    return [ ...this, ...items ];
}

Array.prototype.contains = function<T> (item: T)
{
    return (this.indexOf(item) != -1);
}

Array.prototype.distinct = function <T, TKey>(keySelector: (item: T) => TKey = (item => item as any))
{
    let distinctItems = new Array<T>();
    let isKeyAdded = new Map<TKey, boolean>();

    for (let i = 0; i < this.length; i++)
    {
        var item = this[i];
        var key = keySelector(item);

        if (!isKeyAdded.has(key))
        {
            isKeyAdded.set(key, true);
            distinctItems.push(item);
        }
    }

    return distinctItems;
}

Array.prototype.ancestorsOf = function <T, TKey>(key: TKey, keySelector: (item: T) => TKey, parentKeySelector: (item: T) => TKey, includeSelf: boolean = false)
{
    throw "not tested";
    let ancestors = new Array<T>();

    let item = this.filter(i => keySelector(i) == key)[0];
    if (!item)
    {
        return ancestors;
    }
    if (includeSelf)
    {
        ancestors.push(item);
    }

    let parent = this.filter(i => keySelector(i) == parentKeySelector(item))[0];
    if (parent)
    {
        let ancestorsOfItem = this.ancestorsOf(keySelector(parent), keySelector, parentKeySelector, true);
        ancestors.push(...ancestorsOfItem);
    }

    return ancestors;
}

Array.prototype.groupBy = function <T, TKey>(keySelector: (item: T) => TKey)
{
    let groups = new Array<KeyValue<TKey, Array<T>>>();
    for (let i = 0; i < this.length; i++)
    {
        let item = this[i];
        let key = keySelector(item);
        let group = groups.find(i => i.key == key);

        if (group == null)
        {
            group = new KeyValue(key, new Array<T>())
            groups.push(group);
        }

        group.value.push(item);
    }
    return groups;
}

const rootElement = document.getElementById("root")
ReactDOM.render(
    <React.StrictMode>
		<Provider store={store}>
        <App />
		</Provider>
    </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
