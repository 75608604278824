import { Component } from "react";
import "../../styles/css/table.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { appContext } from "../../AppContext";

class VisitorsToday extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            spaces: [],
            filter: "",
        };
    }

    componentDidMount()
    {
        const { match }: any = this.props;
        const filter = match.url.split('=')[1];
        this.setState({ filter: filter });

        apis.getSpacesByFilter(filter).then((res) =>
        {
            if (res.data.length === 0)
            {
                this.setState({
                    spaces: res.data
                });
            } else
            {
                this.setState({ spaces: res.data });
            }
        }).catch((error: any) => { });
    }

    render()
    {
        const { lightModeTheme }: any = this.props;
        const { spaces, filter }: any = this.state;

        const data = {
            columns: [
                {
                    label: this.labels.HubLabelVisitStart,
                    field: "Booking_Start",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelVisitEnd,
                    field: "Booking_End",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelVisitorEmail,
                    field: "Visitor_Email",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelvisitor,
                    field: "Visitor_First_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCompany,
                    field: "Visitor_Company",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelHostName,
                    field: "Visit_Host_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelStatus,
                    field: "Visit_Status",
                    sort: "",
                    width: 50
                },
            ],
            rows: spaces
        };

        return (

            <div className="rightPanel-main-content">
                <div className="page-title mb-20 row space-between">
                    <div><h1 >{this.labels.HubTabToday}</h1></div>
                    <Link to={{ pathname: `/portfolio-setup-spaces/edit/0/` + filter + `/0`, }}>
                        <button className="button-primary  ">{this.labels.HubButtonAdd}</button>
                    </Link>
                </div>
                <MDBDataTable className="customsizeCol7" entriesOptions={[5, 10, 50, 100]} entries={5} hover data={data} />
            </div>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(VisitorsToday);

export interface IProps
{
}

export interface IState
{
    spaces: [];
    filter: string;
}
