import { DateTime } from "luxon";
import Guid from "./Guid";

export class Event<TEventArgument>
{
    private handlers = new Array<IEventHandler<TEventArgument>>();

    public add(handler: (arg: TEventArgument, sender?: object) => Promise<void>): string
    {
        const ref = (new Guid()).toString();
        this.handlers.push({ ref: ref, handler: handler });
        console.log(`${DateTime.now().toHourMinuteSeconds()} Added event handler '${ref}' (${this.handlers.length} handlers)`);
        return ref;
    }

    public remove(ref: string): void
    {
        const handlers = this.handlers.filter(i => i.ref == ref);
        for (let i = 0; i < handlers.length; i++)
        {
            const handler = handlers[i];
            const handlerIndex = this.handlers.indexOf(handler);
            this.handlers.splice(handlerIndex, 1);
            console.log(`${DateTime.now().toHourMinuteSeconds()} Removed event handler '${ref}' (${this.handlers.length} handlers)`);
        }
    }

    public async notify(arg: TEventArgument, sender?: object): Promise<void>
    {
        await Promise.all(this.handlers.map(i => i.handler(arg, sender)));
    }
}

interface IEventHandler<TEventArgument>
{
    ref: string;
    handler: (arg: TEventArgument, sender?: object) => Promise<void>;
}
