import { Component } from "react";
import "../../styles/css/table.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import Spinner from "../../Components/Spinner";
import TablePanelInfo from "../../Components/table/TablePanelInfo"
import { appContext, IPartialAppState } from "../../AppContext";
import { DateTime } from "luxon";

class FacilityDirectedCleaning extends Component {
  private alert = appContext().alert;
  private appState = appContext().state;
  private labels = appContext().labels;
  state = {
    isLoading: false,
    spaces: [{ id: 0, name: "" }],
    filter: "Building",
    valueBox: 0,
    selectedBuildingId: this.appState.buildingId.toString(),
  };

  public async componentDidMount() {
    const { match }: any = this.props;
    this.setState({
      isLoading: true
    })
    
    this.appState.subscribe(this, i => this.appStateChanged(i));

    const buildingIdParam = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingIdParam }, this);
    
    this.getDetails();
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void {
    if(prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.getDetails();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
      if (state.buildingId != undefined)
      {
          this.setState({ selectedBuildingId: state.buildingId?.toString() });
          history.push(match.path.replace(":buildingid", state.buildingId))
      }
  }

  getDetails = () => {
    this.setState({ isLoading: true })
    apis.getDirectedCleaning(this.state.selectedBuildingId).then((res) => {
      res.data.forEach((element: any) => {
        element.Space_Last_Occupied = DateTime.fromISO(element.Space_Last_Occupied).toFormat("dd/MM/yyyy HH:mm");
      });
      this.setState({
        spaces: res.data,
        isLoading: false
      })
      this.setState({ isLoading: false })
    }).catch((error: any) => {
      this.setState({
        isLoading: false
      })
    });
  }

  excelExportButtonStyle = (table: any) => {
    let styleClass = "button-primary-disable mr-3 e-control e-btn e-lib"
    if (table && table.rows && table.rows[0]) {
      styleClass = "button-primary e-control mr-3 e-btn e-lib"
    }
    return styleClass
  }

  handleDownloadExcelDirectedCleaning = async () => {
    this.alert.show("", this.labels.HubLabelWorkInProgressLabel);
  }

  render() {
    const { lightModeTheme }: any = this.props;
    const { spaces, valueBox }: any = this.state;

    const data = {
      columns: [
        {
          label: this.labels.HubLabelSpaceName,
          field: "Space_Name",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSpaceClass,
          field: "Space_Class",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSpaceType,
          field: "Space_Type",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSpaceId,
          field: "Space_Id",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabellastOccupiedLabel,
          field: "Space_Last_Occupied",
          sort: "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: spaces
    };

    const dataEmpty = {
      columns: [
        {
          label: this.labels.HubLabelSpaceName,
          field: "Space_Name",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSpaceClass,
          field: "Space_Class",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSpaceType,
          field: "Space_Type",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelSpaceId,
          field: "Space_Id",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabellastOccupiedLabel,
          field: "Space_Last_Occupied",
          sort: "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: []
    };

    return (
      <>
            <div className="rightPanel-main-content">
              <div className="table-panel">
                <TablePanelInfo
                  tableHeading={this.labels.HubTabOpservDirectedCleaning}
                  iconSrc="/images/Meeting Room.svg"
                />
                <div className="table-panel-content">
                <button className={this.excelExportButtonStyle(data)} disabled={data.rows[0] ? false : true} onClick={this.handleDownloadExcelDirectedCleaning}>{this.labels.HubButtonExport}</button>
                </div>
                <div className="position-relative">
                  {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                  {spaces.length !== 0 ?
                    <MDBDataTable className="standardTable tablePageNoButtons tableDirectedCleaning" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={data} />
                    :
                    <MDBDataTable className="standardTable tablePageNoButtons tableDirectedCleaning" displayEntries={false} entries={50} hover data={dataEmpty} />
                  }
                </div>
              </div>
            </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProviders,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(FacilityDirectedCleaning);
