import { Component } from "react";
import "../../styles/css/table.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { handleResDataLength, handleTableSpaceError, multiSort } from "../../Common/Helper";
import Spinner from "../../Components/Spinner"
import TablePanelInfo from "../../Components/table/TablePanelInfo"
import { appContext } from "../../AppContext";


class IRoles extends Component {
  private labels = appContext().labels;
  private local = appContext().localStorageProvider;

  state = {
    isLoadingToGetIdentityProvidersRoles: false,
    isLoadingToGetRightList: false,
    currentPageTitle: this.labels.HubLabelRoles,
    providersDisplayNames: [],
    providersNames: [
      { name: this.labels.HubLabelNative, identityProviderId: 1 },
      { name: "Azure AD", identityProviderId: 2 },
      { name: "Okta", identityProviderId: 3 },
    ],
    isCreateRight: false,
  };

  componentDidMount() {
    sessionStorage.removeItem("roleTitle")
    sessionStorage.removeItem("roleID")
    sessionStorage.removeItem('userID');
    sessionStorage.removeItem('userTitle');
    // sessionStorage.clear();
    this.setState({ isLoadingToGetIdentityProvidersRoles: true, })
    apis.getIdentityProvidersRoles().then((res) => {
      for (let j = 0; j < res.data.length; j++) {
        let params = res.data[j];
        let isOwnerStatus = "";
        if (params.isOwner === 1) {
          isOwnerStatus = "Yes";
        }
        if (params.isOwner === 0) {
          isOwnerStatus = "No";
        }
        res.data[j] = {
          ...res.data[j],
          isOwner: isOwnerStatus,
          clickEvent: () => this.handleClick(params),
          'Option': <button className="btn btn-success" onClick={() => this.handleClick(params)} >Edit </button>
        }
      }
      this.setState({
        providersDisplayNames: res.data,
        isLoadingToGetIdentityProvidersRoles: false,
      })
      // handleResDataLength(this, res, this.labels.HublabelErrorEmptyData);
    }).catch((error: any) => {
      const tableError = handleTableSpaceError(this, error, this.labels.HubLabelTokenErorr);
      this.setState({
        ...tableError
      })
      this.setState({ isLoadingToGetIdentityProvidersRoles: false, })
    });
    this.getRightList();
  }

  getRightList = () => {
    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.Roles && res.ADMINPORTAL.Roles.indexOf("Create") > -1) {
      this.setState({
        isCreateRight: true
      })
    }
  }

  getIDName = (identityProviderId: any) => {
    const { identityProvidersStore }: any = this.props;
    const newarr =
      identityProvidersStore.length &&
      identityProvidersStore.filter(
        (e: any) => e.identityProviderId === identityProviderId
      );
    const { name }: any = newarr && newarr[0];
    return name;
  };

  handleClick(params: any) {
    const { history }: any = this.props;
    history.push("security-roles/rolesdetails/" + params.id);
  }

  render() {
    const { lightModeTheme }: any = this.props;
    const { providersDisplayNames }: any = this.state;

    const data = {
      columns: [
        {
          label: this.labels.HubLabelName,
          field: "name",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelIdentityprovider,
          field: "identityProviderName",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelType,
          field: "securityProviderTypeName",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelIsOwner,
          field: "isOwner",
          sort: "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: multiSort(providersDisplayNames, { name: 'asc' })
    };


    return (
          <div className="rightPanel-main-content">
            <div className="table-panel">
              <TablePanelInfo
                tableHeading={this.labels.HubLabelRoles}
                iconSrc="/images/User.svg"
              />
              <div className="table-panel-content">
                <Link to={{ pathname: "security-roles/rolesdetails/0", state: { currentPageTitle: 'New Role', lightModeTheme: lightModeTheme } }} >
                  {this.state.isCreateRight === false &&
                    <button className="button-primary-disable" disabled={true}>{this.labels.HubButtonNewRole}</button>
                  }
                  {this.state.isCreateRight === true &&
                    <button className="button-primary">{this.labels.HubButtonNewRole}</button>
                  }
                </Link>
              </div>
              <div className="position-relative">
                {(this.state.isLoadingToGetIdentityProvidersRoles || this.state.isLoadingToGetRightList) && <Spinner />}
                <MDBDataTable className="standardTable tablePageNoButtons tableRoles" searchLabel={this.labels.HubLabelMySearchLabel} displayEntries={false} entries={50} hover data={data} />
              </div>
            </div>
          </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProviders,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(IRoles);