import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../App.css";
import { connect } from "react-redux";
import Container from '@mui/material/Container';
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import IbssFooter from "../../Components/uicomponents/IbssFooter";
import { appContext } from "../../AppContext";
class OutOfService extends Component
{
    private labels = appContext().labels;

    public render(): JSX.Element
    {
        return (
            <>
            <Box sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100vh',
                padding: '40px 0px',
                backgroundColor: (theme) => theme.palette.grey[400] // uiMidTone
            }}>
                <Container sx={{display:"flex",justifyContent:"center"}}>
                    <Box>
                    <Card className="mt-4" sx={{width:"1052px"}}>
                        <CardContent sx={{display:"flex",alignItems:"center",height:"100%"}}>
                        <Box className="text-center" sx={{padding:"40px"}}>
                            <Box sx={{ padding: '5px', gap: '10px', boxSizing: 'border-box' }}>
                                <img src={`/images/Logos/iconics-ico-b-w.png`} style={{height:"75px"}} alt="Iconics Roamer logo" />
                            </Box>
                            <Box >
                            <Typography variant="h4" className="heading-style mb-2">{this.labels.funcOutOfServiceTitle_S}</Typography>
                            </Box>
                            <Box sx={{display:"flex",justifyContent:"center"}}>
                            <Typography className="text-style" sx={{width:"48%"}}>{this.labels.funcOutOfServiceDescription_S}</Typography>
                            </Box>
                        </Box>
                        </CardContent>
                    </Card>
                    </Box>
                    </Container>
                    <IbssFooter />
                </Box>
            </>
        );
    }
}


export default OutOfService;
