import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
// import { enableRipple } from "@syncfusion/ej2-base";
import "../../styles/css/form.scss";
import Inputbox from "../../Components/uicomponents/Inputbox";
import ConfirmModal from "../../Components/uicomponents/ConfirmModal";
import Submenu from "../../Components/SubMenu";
import { Link } from "react-router-dom";
import LoadingOverlay from "../../Components/LoadingOverlay"
import { convertApiResponseObjectToLower, convertObjectVariableFirstLatterToUpperCase } from "../../Common/Helper"
import { appContext } from "../../AppContext";

// enableRipple(true);

class ParameterDetails extends Component {

  private labels = appContext().labels;
  private local = appContext().localStorageProvider;

  state = {
    isLoadingToSave: false,
    filter: "",
    loading: false,
    activePage: 0,
    updates: false,
    nodeId: null,
    title: "",
    id: null,
    parameter_Name: null,
    parameter_Value: null,
    parameter_Label: null,
    parameter_Section: null,
    parameter_Units: null,
    parameter_Description: null,
    parameter_Type: null,
    parameter_Scalar_LowLimit: null,
    parameter_Scalar_HighLimit: null,
    parameter_Toggle_Values: null,
    parameter_Toggle_ValueCount: null,
    organisationState: "",
    organisationPath: "",
    organisationId: null,
    showOrganisation: false,
    regionState: "",
    regionPath: "",
    regionId: null,
    showRegion: false,
    buildingState: "",
    buildingPath: "",
    buildingId: null,
    showBuilding: false,
    floorState: "",
    floorPath: "",
    floorId: null,
    showFloor: false,
    zoneState: "",
    zonePath: "",
    zoneId: null,
    showZone: false,
    roomState: "",
    roomPath: "",
    roomId: null,
    showRoom: false,
    deskState: "",
    deskPath: "",
    deskId: null,
    showDesk: false,
    spaceFilter: "",
    show: false,
    isDelete: false,
    isUpdateRight: false,
    isDeleteRight: false,
    apiVersion: 11
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    if (history.location.state === 'cancel') {
      this.setState({ activePage: 2 });
    }
    let versions = 11;
    const version = this.local.getVersion();
    if (version !== 0) {
      versions = version;
    }
    this.setState({
      spaceFilter: sessionStorage.getItem('spaceFilter'),
      apiVersion: versions
    })
    if (match.params.id !== "0" && match.params.nodeid) {
      this.getRightList();
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        id: parseInt(match.params.id),
        filter: match.params.filter
      }, () => {
        this.getDetails(this.state.id, this.state.filter);
      })
    } else {
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        title: "New Parameter",
        filter: match.params.filter,
        id: 0,
        isUpdateRight: true,
      })
    }

  }

  getRightList = () => {

    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.Parameters && res.ADMINPORTAL.Parameters.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.Parameters && res.ADMINPORTAL.Parameters.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getDetails = (id: any, filter: any) => {
    this.setState({ loading: true });
    const nodeId = this.state.nodeId;

    if (sessionStorage.getItem('showOrganisation') === "true") {
      this.setState({
        showOrganisation: true,
        organisationState: sessionStorage.getItem('organisationState'),
        orgnisationnPath: sessionStorage.getItem('orgnisationnPath'),
        organisationId: sessionStorage.getItem('organisationId')
      })
    }

    if (sessionStorage.getItem('showRegion') === "true") {
      this.setState({
        showRegion: true,
        regionState: sessionStorage.getItem('regionState'),
        regionPath: sessionStorage.getItem('regionPath'),
        regionId: sessionStorage.getItem('regionId')
      })
    }

    if (sessionStorage.getItem('showBuilding') === "true") {
      this.setState({
        showBuilding: true,
        buildingState: sessionStorage.getItem('buildingState'),
        buildingPath: sessionStorage.getItem('buildingPath'),
        buildingId: sessionStorage.getItem('buildingId')
      })
    }
    if (sessionStorage.getItem('showFloor') === "true") {
      this.setState({
        showFloor: true,
        floorState: sessionStorage.getItem('floorState'),
        floorPath: sessionStorage.getItem('floorPath'),
        floorId: sessionStorage.getItem('floorId')
      })
    }
    if (sessionStorage.getItem('showZone') === "true") {
      this.setState({
        showZone: true,
        zoneState: sessionStorage.getItem('zoneState'),
        zonePath: sessionStorage.getItem('zonePath'),
        zoneId: sessionStorage.getItem('zoneId')
      })
    }
    if (sessionStorage.getItem('showRoom') === "true") {
      this.setState({
        showRoom: true,
        roomState: sessionStorage.getItem('roomState'),
        roomPath: sessionStorage.getItem('roomPath'),
        roomId: sessionStorage.getItem('roomId')
      })
    }
    if (sessionStorage.getItem('showDesk') === "true") {
      this.setState({
        showDesk: true,
        deskState: sessionStorage.getItem('deskState'),
        deskPath: sessionStorage.getItem('deskPath'),
        deskId: sessionStorage.getItem('deskId')
      })
    }

    apis.getParameterById(nodeId, id).then((res) => {
      const lowerCaseData = convertApiResponseObjectToLower(res.data)
      this.setState({ ...lowerCaseData });
      this.setState({
        loading: false,
        title: lowerCaseData.parameter_Name
      });
    }).catch((error: any) => { });

  };
  submit = () => {
    this.setState({ isLoadingToSave: true });
    const { match, history }: any = this.props;
    const { updates, nodeId, id, parameter_Name, parameter_Value, parameter_Label, parameter_Section,
      parameter_Units, parameter_Description, parameter_Type, parameter_Scalar_LowLimit,
      parameter_Scalar_HighLimit, parameter_Toggle_Values, parameter_Toggle_ValueCount }: any = this.state;

    let payload = {
      "parameter_Name": parameter_Name,
      "parameter_Value": parameter_Value,
      "parameter_Label": parameter_Label,
      "parameter_Section": parameter_Section,
      "parameter_Units": parameter_Units,
      "parameter_Description": parameter_Description,
      "parameter_Type": parameter_Type,
      "parameter_Scalar_LowLimit": parameter_Scalar_LowLimit,
      "parameter_Scalar_HighLimit": parameter_Scalar_HighLimit,
      "parameter_Toggle_Values": parameter_Toggle_Values,
      "parameter_Toggle_ValueCount": parseInt(parameter_Toggle_ValueCount)
    }

    if (this.state.apiVersion > 10) {
      // use upperCase
      payload = convertObjectVariableFirstLatterToUpperCase(payload)
    } else if (this.state.apiVersion === 10) {
      // use lowerCase
    }

    if (match.params.id === "0") {
      this.create(nodeId, payload);
    } else {
      this.update(nodeId, id, payload);
    }
  };

  create = (nodeId: any, payload: any) => {
    const { history }: any = this.props;
    apis.createParameter(nodeId, payload).then((res) => {
      const { history }: any = this.props;
      this.setState({
        updates: true,
        isLoadingToSave: false,
      }, () => {
        history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/Parameter/nodeid=" + nodeId + "/id=" + res.data.parameter_Id);
        window.location.reload();
      })
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false });
    });
  }

  update = (nodeId: any, id: any, payload: any) => {
    const { match, history }: any = this.props;
    const { updates }: any = this.state;
    if (updates) {
      apis.updateParameterById(nodeId, id, payload).then((res) => {
        this.setState({ isLoadingToSave: false });
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/2");
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false });
      });
    } else {
      this.setState({ isLoadingToSave: false });
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/2");
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };

  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }
  delete = () => {
    const { match }: any = this.props;
    const id = match.params.id;
    const nodeId = this.state.nodeId
    if (this.state.isDelete) {
      apis.deleteParameterById(nodeId, id).then((res) => {
        const { history }: any = this.props;
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/2");
      }).catch((error: any) => { });
    }
  };
  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates, nodeId } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/2");
    }
  }

  emptyLocalstorage = (filter: any) => {
    if (filter === "Root") {
      // console.log("Root")
      sessionStorage.setItem('showOrganisation', "");
      sessionStorage.setItem('organisationState', "");
      sessionStorage.setItem('orgnisationnPath', '');
      sessionStorage.setItem('organisationId', "");
    }
    if (filter === "Root" || filter === "Region") {
      // console.log("Region")
      sessionStorage.setItem('showRegion', "");
      sessionStorage.setItem('regionState', "");
      sessionStorage.setItem('regionPath', '');
      sessionStorage.setItem('regionId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building") {
      // console.log("Building")
      sessionStorage.setItem('showBuilding', "");
      sessionStorage.setItem('buildingState', "");
      sessionStorage.setItem('buildingPath', '');
      sessionStorage.setItem('buildingId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor") {
      // console.log("Floor")
      sessionStorage.setItem('showFloor', "");
      sessionStorage.setItem('floorState', "");
      sessionStorage.setItem('floorPath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone") {
      // console.log("Zone")
      sessionStorage.setItem('showZone', "");
      sessionStorage.setItem('zoneState', "");
      sessionStorage.setItem('zonePath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces") {
      // console.log("Spaces")
      sessionStorage.setItem('showRoom', "");
      sessionStorage.setItem('roomState', "");
      sessionStorage.setItem('roomPath', '');
      sessionStorage.setItem('roomId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces" || filter === "Desk") {
      // console.log("Desk")
      sessionStorage.setItem('showDesk', "");
      sessionStorage.setItem('deskState', "");
      sessionStorage.setItem('deskPath', '');
      sessionStorage.setItem('deskId', "");
    }
  }

  linkPath = (data: any) => {
    const { filter } = this.state
    const { history }: any = this.props;

    if (data === "Root") {
      this.setState({
        showOrganisation: false,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Root");
        history.push("/portfolio-setup-spaces/edit/" + this.state.organisationId + "/Root");
        // this.getDetails(this.state.organisationId, 'Root');
      })
    }

    if (data === "Region") {
      this.setState({
        showOrganisation: true,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Region");
        history.push("/portfolio-setup-spaces/edit/" + this.state.regionId + "/Region");
        // this.getDetails(this.state.regionId, 'Region');
      })

    }

    if (data === "Building") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Building");
        history.push(this.state.buildingPath);
        // this.getDetails(this.state.buildingId, 'Building');
      })

    }

    if (data === "Floor") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Floor");
        history.push(this.state.floorPath);
        // this.getDetails(this.state.floorId, 'Floor');
      })

    }

    if (data === "Zone") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Zone");
        history.push(this.state.zonePath);
        // this.getDetails(this.state.zoneId, 'Zone');
      })
    }

    if (data === "Spaces") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Spaces");
        history.push(this.state.roomPath);
        // this.getDetails(this.state.roomId, 'Spaces');
      })
    }

    if (data === "Desk") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: true,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Desk");
        history.push(this.state.deskPath);
        // this.getDetails(this.state.deskId, 'Desk');
      })
    }

  }

  render() {
    const { parameter_Name, parameter_Value, parameter_Label, parameter_Section, parameter_Units,
      parameter_Description, parameter_Type, parameter_Scalar_LowLimit, parameter_Scalar_HighLimit,
      parameter_Toggle_Values, parameter_Toggle_ValueCount }: any = this.state;
    const { history }: any = this.props;

    const parameterNameFld = {
      name: "parameter_Name",
      label: this.labels.HubLabelName,
      value: parameter_Name,
      placeholder: this.labels.HubLabelNamePlaceholderText,
      onchange: this.change,
    }

    const parameterTypeFld = {
      name: "parameter_Type",
      label: this.labels.HubLabelType,
      value: parameter_Type,
      placeholder: this.labels.HubLabelTypeLabelPlaceholder,
      onchange: this.change,
    }

    const parameterLabelFld = {
      name: "parameter_Label",
      label: this.labels.HubLabelLabel,
      value: parameter_Label,
      placeholder: this.labels.HubLabelLabelPlaceholder,
      onchange: this.change,
    }

    const parameterSectionFld = {
      name: "parameter_Section",
      label: this.labels.HubLabelSection,
      value: parameter_Section,
      placeholder: this.labels.HubLabelSectionPlaceholder,
      onchange: this.change,
    }

    const parameterDescriptionFld = {
      name: "parameter_Description",
      label: this.labels.HubLabelDescription,
      value: parameter_Description,
      placeholder: this.labels.HubLabelDescriptionPlaceholder,
      onchange: this.change,
    }

    const parameterToggleValuesFld = {
      name: "parameter_Toggle_Values",
      label: this.labels.HubLabelToggleValues,
      value: parameter_Toggle_Values,
      placeholder: this.labels.HubLabelToggleValuesPlaceholder,
      onchange: this.change,
    }

    const parameterToggleValueCountFld = {
      name: "parameter_Toggle_ValueCount",
      label: this.labels.HubLabelToggleValueCount,
      value: parameter_Toggle_ValueCount,
      placeholder: this.labels.HubLabelToggleValueCountPlaceholder,
      onchange: this.change,
    }

    const parameterValueFld = {
      name: "parameter_Value",
      label: this.labels.HubLabelInitialValue,
      value: parameter_Value,
      placeholder: this.labels.HubLabelInitialValuePlaceholder,
      onchange: this.change,
    }

    const parameterScalarLowLimitFld = {
      name: "parameter_Scalar_LowLimit",
      label: this.labels.HubLabelLowLimit,
      value: parameter_Scalar_LowLimit,
      placeholder: this.labels.HubLabelLowLimitPlaceholder,
      onchange: this.change,
    }

    const parameterScalarHighLimitFld = {
      name: "parameter_Scalar_HighLimit",
      label: this.labels.HubLabelHighLimitLabel,
      value: parameter_Scalar_HighLimit,
      placeholder: this.labels.HubLabelHighLimitPlaceholder,
      onchange: this.change,
    }

    const parameterUnitsFld = {
      name: "parameter_Units",
      label: this.labels.HubLabelUnits,
      value: parameter_Units,
      placeholder: this.labels.HubLabelUnitsPlaceholder,
      onchange: this.change,
    }

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabProperties,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.parameter_Name,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.parameter_Name,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/portfolio-setup-spaces/edit/" + this.state.nodeId + "/" + this.state.filter + "/2") },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }
    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
          <div className="rightPanel">
            <Submenu {...props} />
            <div className="rightPanel-main-content-form">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}
              <div className="form-panel">
                <div className="form-panel-heading">
                  <div className="form-panel-breadcrumbs">
                    <div className="breadcrumbs">
                      {this.state.id === 0 ?
                        <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubTabSpaceParameters}</Link>
                        :
                        <div>
                          <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.spaceFilter }}>{this.labels.HubLabelSpaces}</Link>
                          {this.state.showOrganisation && this.state.organisationState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.organisationPath }} onClick={() => this.linkPath('Root')}> / {this.state.organisationState}</Link>)}
                          {this.state.showRegion && this.state.regionState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.regionPath }} onClick={() => this.linkPath('Region')}> / {this.state.regionState}</Link>)}
                          {this.state.showBuilding && this.state.buildingState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.buildingPath }} onClick={() => this.linkPath('Building')}> / {this.state.buildingState}</Link>)}
                          {this.state.showFloor && this.state.floorState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.floorPath }} onClick={() => this.linkPath('Floor')}> / {this.state.floorState}</Link>)}
                          {this.state.showZone && this.state.zoneState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.zonePath }} onClick={() => this.linkPath('Zone')}> / {this.state.zoneState}</Link>)}
                          {this.state.showRoom && this.state.roomState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.roomState }} onClick={() => this.linkPath('Spaces')}> / {this.state.roomState}</Link>)}
                          {this.state.showDesk && this.state.deskState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.deskState }} onClick={() => this.linkPath('Desk')}> / {this.state.deskState}</Link>)}
                        </div>
                      }


                    </div>
                    <div className="page-title mb-20"><h1>{this.state.title}</h1></div>
                  </div>
                </div>

                {this.state.activePage === 0 && (
                  <div className="main-content">
                    {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                    <div className="height-control">
                      {this.state.loading || (
                        <div className="card card-scroller">
                          <div className="p-45">
                            <form>

                              <div className="row" >
                                <div className="col-6 pr-2"><Inputbox {...parameterNameFld} /> </div>
                                <div className="col-6"><Inputbox {...parameterTypeFld} /> </div>
                              </div>

                              <div className="row" >
                                <div className="col-3 pr-2 input-controls"><Inputbox {...parameterLabelFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...parameterSectionFld} /> </div>

                                <div className="col-6">
                                  <Inputbox {...parameterDescriptionFld} />
                                </div>
                              </div>

                              <div className="row" >
                                <div className="col-8 pr-2">
                                  <Inputbox {...parameterToggleValuesFld} />
                                </div>

                                <div className="col-4">
                                  <Inputbox {...parameterToggleValueCountFld} />
                                </div>
                              </div>

                              <div className="row" >
                                <div className="col-3 pr-2 input-controls"><Inputbox {...parameterValueFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...parameterScalarLowLimitFld} /> </div>

                                <div className="col-3 pr-2 input-controls"><Inputbox {...parameterScalarHighLimitFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...parameterUnitsFld} /> </div>
                              </div>

                            </form>
                          </div>
                        </div>)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="rightPanel-main-button-group">
              <div>
                <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                {(this.state.id !== 0 && this.state.isDeleteRight === false) && (
                  <button className="button-tertiary-disable mr-2" disabled={true}>{this.labels.HubButtonDelete}</button>
                )}
                {(this.state.id !== 0 && this.state.isDeleteRight === true) && (
                  <button className="button-tertiary mr-2" onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                )}
              </div>
              <div>
                {this.state.isUpdateRight === false &&
                  <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                }
                {this.state.isUpdateRight === true &&
                  <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                }
              </div>
            </div>
          </div>

      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(ParameterDetails);