import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IGetActiveNotificationEndpoint, Notification } from "./GetActiveNotificationEndpoint";

export class NotificationRepository implements INotificationRepository
{
    private getActiveNotificationEndpoint: IGetActiveNotificationEndpoint;

    constructor(getActiveNotificationEndpoint: IGetActiveNotificationEndpoint)
    {
        this.getActiveNotificationEndpoint = getActiveNotificationEndpoint;
    }

    public async getActiveNotification(nodeId: number): Promise<Notification[]>
    {
        return this.getActiveNotificationEndpoint.execute(nodeId);
    }
}

export interface INotificationRepository
{
    getActiveNotification(nodeId: number): Promise<Notification[]>;
}
