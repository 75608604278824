import React from "react";
import { appContext } from "../../../AppContext";
import { Typography, TextField, MenuItem } from "@mui/material";
import IbssButton from "../../../Components/uicomponents/IbssButton";
import IbssDialog from "../../../Components/uicomponents/IbssDialog";
import { CostCodeType } from "../../../Providers.Api/CostCodes/CostCodeRepository";
import { CostCodeSource } from "../../../Providers.Api/CostCodes/CostCodeRepository";

class CostCodesModal extends React.Component<IProps, IState>
{
    private get labels() { return appContext().labels; }

    private costCodeTypes =
    [
        this.labels.HubLabelAny,
        this.labels.HubLabelIBSSStandardCostCode,
        this.labels.HubLabelClientCode,
        this.labels.HubLabelDepartmentCode,
    ];

    private costCodeSources =
    [
        this.labels.HubLabelAny,
        CostCodeSource.SFTP,
        CostCodeSource.API,
        this.labels.HubLabelManual,
    ];

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            costCodeType: CostCodeType.Any,
            costCodeSource: CostCodeSource.Any,
        }
    }

    public componentDidMount(): void
    {
        this.setState({
            costCodeType: this.props.costCodeType,
            costCodeSource: this.props.costCodeSource,
        });
    }

    private costCodeTypeChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const costCodeType = e.target.value as CostCodeType;
        this.setState({ costCodeType: costCodeType });
    }
    
    private filterModalCancelled(): void
    {
        this.props.handleFilterModal(false);
        // reset states to original values if modal is closed or cancelled.
        this.setState({
            costCodeType: this.props.costCodeType,
            costCodeSource: this.props.costCodeSource,
        })
    }

    private filterModalSubmitted(): void
    {      
        // update parent component CostCodes' states on submission.
        this.props.changeCostCodeSource(this.state.costCodeSource);
        this.props.changeCostCodeType(this.state.costCodeType);
        this.props.handleFilterModal(false);
        this.props.handleFilterCostCodes(this.state.costCodeSource, this.state.costCodeType);
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
            open={this.props.openFilterModal}
            onClose={() => this.filterModalCancelled()}
            fullWidth
            header={this.labels.HubLabelFilter}
            content=
            {
                <>
                    <Typography className="mb-3">{this.labels.HubLabelAssignPolicyFilterText}</Typography>
                    <div className="row mb-3">
                        <TextField
                            id="typeSelection"
                            select={true}
                            label={this.labels.HubLabelType}
                            fullWidth
                            value={this.state.costCodeType}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => { this.costCodeTypeChanged(e) }}
                        >
                            {this.costCodeTypes.map(costCodeType => (
                                <MenuItem key={costCodeType} value={costCodeType}>
                                    {costCodeType}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="row">
                        <TextField
                            id="sourceSelection"
                            select={true}
                            label={this.labels.HubLabelSource}
                            fullWidth
                            value={this.state.costCodeSource}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => { this.setState({ costCodeSource: e.target.value as CostCodeSource }) }}
                        >
                            {this.costCodeSources.map(costCodeSource => (
                                <MenuItem key={costCodeSource} value={costCodeSource}>
                                    {costCodeSource}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </>
            }
            footer=
            {
                <>
                    <IbssButton
                        onClick={() => this.filterModalCancelled()}
                        color="secondary"
                        variant="outlined"
                    >
                        {this.labels.HubButtonCancel}
                    </IbssButton>
                    <IbssButton
                        color="primary"
                        variant="contained"
                        size="medium"
                        onClick={() => this.filterModalSubmitted()}
                    >
                        {this.labels.HubLabelOk}
                    </IbssButton>
                </>
            }
        />
        )
    }
}

export default CostCodesModal;

interface IState
{
    costCodeType: CostCodeType,
    costCodeSource: CostCodeSource,
}

interface IProps
{

    costCodeType: CostCodeType,
    costCodeSource: CostCodeSource,
    changeCostCodeType: (type: CostCodeType)=> void,
    changeCostCodeSource: (source: CostCodeSource)=> void,
    handleFilterCostCodes: (source: CostCodeSource, type: CostCodeType) => Promise<void>,
    handleFilterModal: (status: boolean) => void,
    openFilterModal: boolean,
}