import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IBookingPolicy } from "./BookingPolicyRepository";

export class GetBookingPolicyEndpoint implements IGetBookingPolicyEndpoint
{
    public async execute(nodeId: number, bookingPolicyId: string): Promise<IBookingPolicy>
    {
        let queryString = `Booking_Policy_Id,Booking_Policy_Name,Booking_Policy,Node_Id`;
        try
        {
            const response: AxiosResponse<IBookingPolicy> = await axios.get(`${apis.dataEntryApiV2}${nodeId}/BookingPolicies/${bookingPolicyId}?%24select=${queryString}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetBookingPolicyEndpoint
{
    execute(nodeId: number, bookingPolicyId: string): Promise<IBookingPolicy>;
}
