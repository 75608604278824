import { Component } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { getUserPrefrenceJosn } from "../../../../Common/UserPreferenceJSON";
import IbssButton from '../../../../Components/uicomponents/IbssButton';
import { appContext } from '../../../../AppContext';
import { IUserPreferences } from '../../../../Providers.Api/UserPreferenceRepository';

class SearchResultState {
    public searchResults = "List";
    public searchResultUserPref = getUserPrefrenceJosn();
    isLoading = false;
};

class SearchResult extends Component<any, SearchResultState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider

    constructor(props: any) {
        super(props)
        this.state = new SearchResultState();
    };

    componentDidMount(): void {

        //configure search results input
        const response = this.local.getUserPreferences();
        this.setState({
            searchResults: response?.SearchPrefs?.DefaultSearchResults as string,
            searchResultUserPref: response,
        });
    };

    public searchResultChanged(value: string): void {
        this.setState({ searchResults: value });
    };

    public submit = async () => {
        const payload = {
            ...this.state.searchResultUserPref,
            SearchPrefs:
            {
                DefaultBuilding: this.state.searchResultUserPref.SearchPrefs.DefaultBuilding,
                DefaultSearchResults: this.state.searchResults,
            },

        };
        try
        {
            await this.userPreferences.update(payload);
            this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
        }
        catch { }
    };

    render() {
        return (
            <>
                <Grid container >
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleSearchResult}</Typography>

                            <Typography className='card-userPref-text mb-32' variant="body2" gutterBottom>{this.labels.HubSubTitleSearchResult}</Typography>

                        </Grid>

                        <Grid container columns={16} className="mt-4 search-reasult">

                            <Grid item xs={8} className="d-flex justify-content-center align-items-center" onClick={() => this.searchResultChanged("List")} sx={{ backgroundColor: this.state.searchResults === "List" ? "var(--ui-primary-pastel)" : null, borderRadius: this.state.searchResults === "List" ? "8px" : null, border: this.state.searchResults === "List" ? "2px solid var(--ui-primary)" : null }}>
                                <div className='p-24'>
                                    <div className='d-block'>
                                        <img alt="List View" src="/images/List view.svg" width="128px" />
                                    </div>
                                    <div className='d-flex justify-content-center mt-8'>
                                        <IbssButton className='btn-radius' variant={`${this.state.searchResults === "List" ? "contained" : "text"}`}>{this.labels.HubButtonList}</IbssButton>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={8} className={`d-flex justify-content-center align-items-center ${this.state.searchResults === "Map" ? "search-result-active" : null}`} onClick={() => this.searchResultChanged("Map")}>
                                <div className='p-24'>
                                    <div className='d-block'>
                                        <img alt="Map View" src="/images/Map view.svg" width="128px" />
                                    </div>
                                    <div className='d-flex justify-content-center mt-8'>
                                        <IbssButton className='btn-radius' variant={`${this.state.searchResults === "Map" ? "contained" : "text"}`} >{this.labels.HubButtonMap}</IbssButton>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>
                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => { this.submit() }}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
};

export default SearchResult;
