import React, { Component } from "react";
import TablePanelInfo from "./table/TablePanelInfo";
import Spinner from "./Spinner";
import { MDBDataTable } from "mdbreact";
import { appContext } from "../AppContext";

export default class SpacesTable extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get localStorage() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            canExport: false,
        };
    }

    public componentDidMount(): void
    {
        this.setState({ canExport: this.localStorage.hasRight("API.Spaces.Export") });
    }

    public render(): JSX.Element
    {
        const { showDownloadButton, downloadButtonClassName, isDownloadButtonDisabled, downloadButtonClicked, showFilterButton, filterButtonClicked, isLoading, tableData } = this.props
        return (
            <div className="rightPanel-main-content">
                <div className="table-panel">
                    <TablePanelInfo
                        tableHeading={this.props.title}
                        iconSrc="/images/Meeting Room.svg"
                    />
                    <div className="table-panel-content">
                        {this.state.canExport && showDownloadButton &&
                            <button
                                className={downloadButtonClassName}
                                disabled={isDownloadButtonDisabled}
                                onClick={downloadButtonClicked}
                            >
                                {this.labels.HubButtonExport}
                            </button>
                        }
                    </div>
                    {showFilterButton &&
                        <div className="table-panel-content-datepicker" style={{ zIndex: 99 }}>
                            <button className="button-primary button-filter" onClick={filterButtonClicked}>{this.labels.HubLabelFilter}</button>
                        </div>
                    }
                    <div className="position-relative">
                        {(isLoading || isLoading) && <Spinner />}
                        <MDBDataTable
                            className="standardTable tablePageButtons tableOpServSpaces"
                            searchLabel={this.labels.HubLabelMySearchLabel}
                            disableRetreatAfterSorting={true}
                            displayEntries={false}
                            entries={50}
                            hover
                            small
                            data={tableData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

interface IProps
{
    title: string;
    isLoading: boolean;
    showDownloadButton: boolean;
    downloadButtonClassName?: string;
    isDownloadButtonDisabled?: boolean;
    downloadButtonClicked: () => void;
    showFilterButton?: boolean;
    filterButtonClicked?: () => void;
    tableData?:
    {
        columns: Array<ITableDataColumn>
        rows: Array<ITableRow>
    }
}

interface ITableDataColumn
{
    label?: string;
    field?: string;
    sort?: string;
    width?: number;
    searchable?: boolean;
}

interface ITableRow 
{
    Meta_Occ_Type: number;
    Space_Class: string;
    Space_Ext_Occ_Count: number;
    Space_Id: string;
    Space_Name: string;
    Space_Status_Text: string;
    Space_Type: string;
    occupancy: number;
}

export interface IState
{
    canExport: boolean;
}
