import { Component } from "react";
import "../../../styles/css/table.scss";
import "../../../App.css";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray } from "../../../Common/Helper";
import Spinner from "../../../Components/Spinner";
import TablePanelInfo from "../../../Components/table/TablePanelInfo"
import { IPartialAppState, appContext } from "../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../app/constants";
import { DateTime } from "luxon";

class FacilityZones extends Component {
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;

  state = {
    isLoading: false,
    zonesData: [],
    facilityDataSort: { columnName: "", direction: "" },
    zoneList: [],
    selectedBuildingId: this.appState.buildingId.toString(),
  };

  public async componentDidMount() {
    const { history, match, dispatch }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsZones });
    if (this.session.getBuildingId() !== match.params.buildingid) {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.handleGetZonesData();
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void {
    if(prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.handleGetZonesData();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
      if (state.buildingId != undefined)
      {
          this.setState({ selectedBuildingId: state.buildingId.toString() });
          history.push(match.path.replace(":buildingid", state.buildingId));
      }
  }

  getAirStatus(val: number): string {
    let obj: any = { 1: 'Good', 2: 'Fair', 3: 'Poor', 0: 'No Data' };
    return obj[val]
  }

  handleGetZonesData = async () => {
    this.setState({ isLoading: true })
    try {
      const response: any = await apis.getFacilityZonesData(this.session.getBuildingId())
      const responseData = response.data;

      const newResponseData = responseData && responseData[0] && responseData.map((item: any) => {
        let newItem = { ...item }

        newItem.table_Env_Zone_Name = <div onClick={() => this.handleClickZone(item)}>{item.Env_Zone_Name}</div>
        newItem.table_IAQ_Status = <div onClick={() => this.handleClickZone(item)}>{this.getAirStatus(item.IAQ_Status)}</div>
        newItem.table_Env_Temperature = <div onClick={() => this.handleClickZone(item)}>{item.Env_Temperature}{'\u00b0'}C</div>
        newItem.table_Env_Humidity = <div onClick={() => this.handleClickZone(item)}>{item.Env_Humidity}%</div>
        newItem.table_Env_Sound_Level = <div onClick={() => this.handleClickZone(item)}>{item.Env_Sound_Level} dB</div>
        newItem.table_Env_Zone_LastUpdated = <div onClick={() => this.handleClickZone(item)}>{DateTime.fromISO(item.Env_Zone_LastUpdated).toFormat("dd/MM/yyyy HH:mm")}</div>
        return newItem
      })

      this.setState({ isLoading: false, zoneList: newResponseData ? newResponseData : [] })
      this.setState({ isLoading: false, zonesData: responseData ? responseData : [] })
    } catch (error: any) {
      this.setState({
        isLoading: false,
        spaces: [{ id: 0, name: "" }],
      })
    }
  }

  handleClickZone = (item: any) => {
    const { history }: any = this.props;
    history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}/${item.Env_Zone_Id}`)
  }

  handleFacilityDataSort = (e: any) => {
    this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.zoneList, e.column, e.direction)
    this.setState({ zoneList: newArray })
  }

  render() {
    const tableData = {
      columns: [
        {
          label: this.labels.HubLabelName,
          field: "table_Env_Zone_Name",
          sort: this.state.facilityDataSort.columnName == "table_Env_Zone_Name" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label:this.labels.HubLabelStatus,
          field: "table_IAQ_Status",
          sort: this.state.facilityDataSort.columnName == "table_IAQ_Status" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelTemperature,
          field: "table_Env_Temperature",
          sort: this.state.facilityDataSort.columnName == "table_Env_Temperature" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelHumidity,
          field: "table_Env_Humidity",
          sort: this.state.facilityDataSort.columnName == "table_Env_Humidity" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelNoise,
          field: "table_Env_Sound_Level",
          sort: this.state.facilityDataSort.columnName == "table_Env_Sound_Level" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label:this.labels.HubLabelLastUpdate,
          field: "table_Env_Zone_LastUpdated",
          sort: this.state.facilityDataSort.columnName == "Env_Zone_LastUpdated" ? this.state.facilityDataSort.direction : "",
          width: 50
        },

      ],
      rows: this.state.zoneList ? this.state.zoneList : []
    };

    return (
      <>
            <div className="rightPanel-main-content">
              <div className="table-panel">
                <TablePanelInfo
                  tableHeading={this.labels.HubLabelZones}
                  iconSrc="/images/Sidebar_Icons/Light_theme/Zone.svg"
                />
                <div className="table-panel-content">
                  <button className="button-primary">{this.labels.HubButtonExport}</button>
                </div>
                <div className="position-relative">
                  {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                  <MDBDataTable className="standardTable tablePageNoButtons sustainability-table"  searchLabel={this.labels.HubLabelMySearchLabel} disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small onSort={this.handleFacilityDataSort} data={tableData} />
                </div>
              </div>
            </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(FacilityZones);
