import React, { Component } from 'react'
import { connect } from 'react-redux';
import LoadingOverlay from '../../../../Components/LoadingOverlay'
import { Grid } from '@mui/material';
import IbssHorizontalTabs, { TabsParameters } from '../../../../Components/uicomponents/IbssTabs';
import CalendarIcon from '../../../../Components/uicomponents/customMaterialIcons/CalendarIcon';
import RightArrowIcon from '../../../../Components/uicomponents/customMaterialIcons/RightArrowIcon';
import { appContext } from '../../../../AppContext';
import CalendarIntegration from './CalendarIntegration';
import DataPrivacy from './DataPrivacy';
import ManagePin from './ManagePin';
import SecurityIcon from '../../../../Components/uicomponents/customMaterialIcons/SecurityIcon';
import DataPrivacyIcon from '../../../../Components/uicomponents/customMaterialIcons/DataPrivacyIcon';
import IbssSvgIcon from '../../../../Components/uicomponents/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { UPDATE_MAIN_PAGE_TITLE } from '../../../../app/constants';

class Notification extends Component<any, any> {
    private labels = appContext().labels;

    state = {
        isLoading: false,
    };

    render() {
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubMenuAccountSettings });

        const listTabs: TabsParameters[] = [
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.calenderIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelCalendarIntegration}
                        </div>
                    </div>
                ),
                components: <CalendarIntegration />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.dataPrivacyIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelDataPrivacy}
                        </div>
                    </div>
                ),
                components: <DataPrivacy />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.securityIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelManagePIN}
                        </div>
                    </div>
                ),
                components: <ManagePin />,
            },
        ];
        return (
            <>
                {this.state.isLoading && <LoadingOverlay />}
                        <div className='rightPanel-main-content'>
                            <Grid container gap={15} columns={16}>
                                <IbssHorizontalTabs
                                    className='ibss-vertical-tab'
                                    orientation='vertical'
                                    tabs={listTabs}
                                    boxwidth="344px"
                                    sx={{
                                        fontSize: "18px", marginTop: "25px", fontFamily: "Source Sans Pro", backgroundColor: `background.paper`, display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                />
                            </Grid>
                        </div>
            </>
        )
    }
};

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(Notification);
