import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../App.css";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
// import { enableRipple } from "@syncfusion/ej2-base";
import "../../styles/css/form.scss";
import Inputbox from "../../Components/uicomponents/Inputbox";
import ConfirmModal from "../../Components/uicomponents/ConfirmModal";
import Submenu from "../../Components/SubMenu";
import { Link } from "react-router-dom";
import LoadingOverlay from "../../Components/LoadingOverlay"
import { convertApiResponseObjectToLower } from "../../Common/Helper"
import { appContext } from "../../AppContext";

// enableRipple(true);

class SpaceStatesedit extends Component {
  private labels = appContext().labels;
  private local = appContext().localStorageProvider;
  state = {
    isLoadingToSave: false,
    filter: "",
    phoneNumber: null,
    loading: false,
    activePage: 0,
    updates: false,
    identityProviderId: null,
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    displayName: null,
    userPreferences: null,
    concurrencyStamp: '',
    nodeId: null,
    space_State_Id: null,
    space_State_Name: null,
    space_State_Pre_Header: null,
    space_State_Header: null,
    space_State_Body: null,
    space_State_L1CTA_P: null,
    space_State_L1CTA_S: null,
    space_State_L1CTA_T: null,
    space_State_Show_Space: null,
    space_State_Show_Fault: null,
    space_State_Show_Controls: null,
    space_State_Show_Info: null,
    space_State_Show_Schedule: null,
    space_State_Show_Booking: null,
    space_State_UI_Colour: null,
    space_State_Text: null,
    space_State_Icon: null,
    space_State_Value0: null,
    space_State_Value1: null,
    space_State_L1CTA_P_Command: null,
    space_State_L1CTA_S_Command: null,
    space_State_L1CTA_T_Command: null,
    space_State_Validation_Field: null,
    space_State_Alternate: null,
    space_State_Show_Custom: null,
    organisationState: "",
    organisationPath: "",
    organisationId: null,
    showOrganisation: false,
    regionState: "",
    regionPath: "",
    regionId: null,
    showRegion: false,
    buildingState: "",
    buildingPath: "",
    buildingId: null,
    showBuilding: false,
    floorState: "",
    floorPath: "",
    floorId: null,
    showFloor: false,
    zoneState: "",
    zonePath: "",
    zoneId: null,
    showZone: false,
    roomState: "",
    roomPath: "",
    roomId: null,
    showRoom: false,
    deskState: "",
    deskPath: "",
    deskId: null,
    showDesk: false,
    spaceFilter: "",
    show: false,
    isDelete: false,
    isUpdateRight: false,
    isDeleteRight: false,
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    if (history.location.state === 'cancel') {
      this.setState({ activePage: 2 });
    }
    this.setState({
      spaceFilter: sessionStorage.getItem('spaceFilter')
    })
    if (match.params.id !== "0" && match.params.nodeid) {
      this.getRightList();
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        id: match.params.id,
        filter: "SpaceStates"
      }, () => {
        this.getDetails(this.state.id, this.state.filter);
      })
    } else {
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        displayName: "New Space State",
        filter: "SpaceStates",
        isUpdateRight: true,
      })
    }

  }

  getRightList = () => {

    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.SpaceStates && res.ADMINPORTAL.SpaceStates.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.SpaceStates && res.ADMINPORTAL.SpaceStates.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getDetails = (id: any, filter: any) => {
    this.setState({ loading: true });
    const nodeId = this.state.nodeId;

    if (sessionStorage.getItem('showOrganisation') === "true") {
      this.setState({
        showOrganisation: true,
        organisationState: sessionStorage.getItem('organisationState'),
        orgnisationnPath: sessionStorage.getItem('orgnisationnPath'),
        organisationId: sessionStorage.getItem('organisationId')
      })
    }

    if (sessionStorage.getItem('showRegion') === "true") {
      this.setState({
        showRegion: true,
        regionState: sessionStorage.getItem('regionState'),
        regionPath: sessionStorage.getItem('regionPath'),
        regionId: sessionStorage.getItem('regionId')
      })
    }

    if (sessionStorage.getItem('showBuilding') === "true") {
      this.setState({
        showBuilding: true,
        buildingState: sessionStorage.getItem('buildingState'),
        buildingPath: sessionStorage.getItem('buildingPath'),
        buildingId: sessionStorage.getItem('buildingId')
      })
    }
    if (sessionStorage.getItem('showFloor') === "true") {
      this.setState({
        showFloor: true,
        floorState: sessionStorage.getItem('floorState'),
        floorPath: sessionStorage.getItem('floorPath'),
        floorId: sessionStorage.getItem('floorId')
      })
    }
    if (sessionStorage.getItem('showZone') === "true") {
      this.setState({
        showZone: true,
        zoneState: sessionStorage.getItem('zoneState'),
        zonePath: sessionStorage.getItem('zonePath'),
        zoneId: sessionStorage.getItem('zoneId')
      })
    }
    if (sessionStorage.getItem('showRoom') === "true") {
      this.setState({
        showRoom: true,
        roomState: sessionStorage.getItem('roomState'),
        roomPath: sessionStorage.getItem('roomPath'),
        roomId: sessionStorage.getItem('roomId')
      })
    }
    if (sessionStorage.getItem('showDesk') === "true") {
      this.setState({
        showDesk: true,
        deskState: sessionStorage.getItem('deskState'),
        deskPath: sessionStorage.getItem('deskPath'),
        deskId: sessionStorage.getItem('deskId')
      })
    }

    apis.getSpaceStatesById(nodeId, id, filter).then((res) => {
      const newData = convertApiResponseObjectToLower(res.data)
      this.setState({ ...newData });
      this.setState({
        loading: false,
        displayName: newData.space_State_Id
      });
    }).catch((error: any) => { });

  };

  submit = () => {
    this.setState({ isLoadingToSave: true });
    const { match, history }: any = this.props;
    const { updates, nodeId, id, filter, userPreferences }: any = this.state;

    let payload = {
      "space_State_Id": this.state.space_State_Id,
      "space_State_Name": this.state.space_State_Name,
      "space_State_Pre_Header": this.state.space_State_Pre_Header,
      "space_State_Header": this.state.space_State_Header,
      "space_State_Body": this.state.space_State_Body,
      "space_State_L1CTA_P": this.state.space_State_L1CTA_P,
      "space_State_L1CTA_S": this.state.space_State_L1CTA_S,
      "space_State_L1CTA_T": this.state.space_State_L1CTA_T,
      "space_State_Show_Space": this.state.space_State_Show_Space,
      "space_State_Show_Fault": this.state.space_State_Show_Fault,
      "space_State_Show_Controls": this.state.space_State_Show_Controls,
      "space_State_Show_Info": this.state.space_State_Show_Info,
      "space_State_Show_Schedule": this.state.space_State_Show_Schedule,
      "space_State_Show_Booking": this.state.space_State_Show_Booking,
      "space_State_UI_Colour": this.state.space_State_UI_Colour,
      "space_State_Text": this.state.space_State_Text,
      "space_State_Icon": this.state.space_State_Icon,
      "space_State_Value0": this.state.space_State_Value0,
      "space_State_Value1": this.state.space_State_Value1,
      "space_State_L1CTA_P_Command": this.state.space_State_L1CTA_P_Command,
      "space_State_L1CTA_S_Command": this.state.space_State_L1CTA_S_Command,
      "space_State_L1CTA_T_Command": this.state.space_State_L1CTA_T_Command,
      "space_State_Validation_Field": this.state.space_State_Validation_Field,
      "space_State_Alternate": this.state.space_State_Alternate,
      "space_State_Show_Custom": this.state.space_State_Show_Custom
    }

    if (match.params.id === "0") {
      this.create(nodeId, payload);
    } else {
      this.update(nodeId, id, payload);
    }

  };

  create = (nodeId: any, payload: any) => {
    const { history }: any = this.props;
    apis.createSpaceStates(payload, nodeId).then((res) => {
      const { history }: any = this.props;
      this.setState({
        updates: true,
        isLoadingToSave: false,
      }, () => {
        history.push("/spaces-task-types/filter=SpaceStates/nodeid=" + nodeId + "/id=" + res.data.id);
        window.location.reload();
      })
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false });
    });
  }

  update = (nodeId: any, id: any, payload: any) => {
    const { match, history }: any = this.props;
    const { updates, filter }: any = this.state;
    if (updates) {
      apis.updateSpaceStatesDetails(nodeId, id, filter, payload).then((res) => {
        this.setState({ isLoadingToSave: false });
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/Building/10");
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false });
      });
    } else {
      this.setState({ isLoadingToSave: false });
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/Building/10");
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };

  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }
  delete = () => {
    const { nodeId }: any = this.state;
    const { match }: any = this.props;
    const id = match.params.id;
    if (this.state.isDelete) {
      apis.deleteUserById(id).then((res) => {
        const { history }: any = this.props;
        history.push("/spaces-task-types/edit/" + nodeId + "/Building/10");
      }).catch((error: any) => { });
    }
  };
  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates, nodeId } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/Building/10");
    }
  }
  emptyLocalstorage = (filter: any) => {
    if (filter === "Root") {
      // console.log("Root")
      sessionStorage.setItem('showOrganisation', "");
      sessionStorage.setItem('organisationState', "");
      sessionStorage.setItem('orgnisationnPath', '');
      sessionStorage.setItem('organisationId', "");
    }
    if (filter === "Root" || filter === "Region") {
      // console.log("Region")
      sessionStorage.setItem('showRegion', "");
      sessionStorage.setItem('regionState', "");
      sessionStorage.setItem('regionPath', '');
      sessionStorage.setItem('regionId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building") {
      // console.log("Building")
      sessionStorage.setItem('showBuilding', "");
      sessionStorage.setItem('buildingState', "");
      sessionStorage.setItem('buildingPath', '');
      sessionStorage.setItem('buildingId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor") {
      // console.log("Floor")
      sessionStorage.setItem('showFloor', "");
      sessionStorage.setItem('floorState', "");
      sessionStorage.setItem('floorPath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone") {
      // console.log("Zone")
      sessionStorage.setItem('showZone', "");
      sessionStorage.setItem('zoneState', "");
      sessionStorage.setItem('zonePath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces") {
      // console.log("Spaces")
      sessionStorage.setItem('showRoom', "");
      sessionStorage.setItem('roomState', "");
      sessionStorage.setItem('roomPath', '');
      sessionStorage.setItem('roomId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces" || filter === "Desk") {
      // console.log("Desk")
      sessionStorage.setItem('showDesk', "");
      sessionStorage.setItem('deskState', "");
      sessionStorage.setItem('deskPath', '');
      sessionStorage.setItem('deskId', "");
    }
  }


  linkPath = (data: any) => {
    const { filter } = this.state
    const { history }: any = this.props;

    if (data === "Root") {
      this.setState({
        showOrganisation: false,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Root");
        history.push("/portfolio-setup-spaces/edit/" + this.state.organisationId + "/Root");
        // this.getDetails(this.state.organisationId, 'Root');
      })
    }

    if (data === "Region") {
      this.setState({
        showOrganisation: true,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Region");
        history.push("/portfolio-setup-spaces/edit/" + this.state.regionId + "/Region");
        // this.getDetails(this.state.regionId, 'Region');
      })

    }

    if (data === "Building") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Building");
        history.push(this.state.buildingPath);
        // this.getDetails(this.state.buildingId, 'Building');
      })

    }

    if (data === "Floor") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Floor");
        history.push(this.state.floorPath);
        // this.getDetails(this.state.floorId, 'Floor');
      })

    }

    if (data === "Zone") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Zone");
        history.push(this.state.zonePath);
        // this.getDetails(this.state.zoneId, 'Zone');
      })
    }

    if (data === "Spaces") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Spaces");
        history.push(this.state.roomPath);
        // this.getDetails(this.state.roomId, 'Spaces');
      })
    }

    if (data === "Desk") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: true,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Desk");
        history.push(this.state.deskPath);
        // this.getDetails(this.state.deskId, 'Desk');
      })
    }

  }

  render() {
    const { space_State_Value1,
      space_State_Pre_Header, space_State_Text, space_State_UI_Colour, space_State_Show_Fault,
      space_State_Show_Controls, space_State_Show_Info, space_State_Show_Schedule, space_State_Value0,
      space_State_Show_Booking, space_State_Show_Custom, space_State_Validation_Field, space_State_Alternate,
      space_State_Id, space_State_Name, space_State_Body, space_State_L1CTA_P, space_State_L1CTA_P_Command,
      space_State_L1CTA_S, space_State_L1CTA_S_Command, space_State_L1CTA_T, space_State_L1CTA_T_Command,
      space_State_Show_Space, space_State_Icon, space_State_Header }: any = this.state;
    const { history }: any = this.props;

    const spaceStateHeaderFld = {
      name: "space_State_Header",
      label: this.labels.HubLabelHeaderText,
      value: space_State_Header,
      mandatory: true,
      placeholder: this.labels.HubLabelHeaderTextPlaceholder,
      onchange: this.change,
    }

    const spaceStateIconFld = {
      name: "space_State_Icon",
      label: this.labels.HubLabelIcon,
      value: space_State_Icon,
      mandatory: true,
      placeholder: this.labels.HubLabelIconLabelPlaceHolder,
      onchange: this.change,
    }

    const spaceStateShowSpaceFld = {
      name: "space_State_Show_Space",
      label: this.labels.HubLabelShowSpace,
      value: space_State_Show_Space,
      mandatory: true,
      placeholder: this.labels.HubLabelSpaceLabelPlaceholder,
      onchange: this.change,
    }

    const spaceStateL1CTAPFld = {
      name: "space_State_L1CTA_P",
      label: this.labels.HubLabelPrimary,
      value: space_State_L1CTA_P,
      mandatory: true,
      placeholder: this.labels.HubLabelprimaryPlaceholder,
      onchange: this.change,
    }

    const spaceStateL1CTAPCommandFld = {
      name: "space_State_L1CTA_P_Command",
      label: this.labels.HubLabelPrimaryCommand,
      value: space_State_L1CTA_P_Command,
      mandatory: true,
      placeholder: this.labels.HubLabelPrimaryCommandPlaceholder,
      onchange: this.change,
    }

    const spaceStateL1CTASFld = {
      name: "space_State_L1CTA_S",
      label: this.labels.HubLabelSecondary,
      value: space_State_L1CTA_S,
      mandatory: true,
      placeholder: this.labels.HubLabelSecondaryPlaceholder,
      onchange: this.change,
    }

    const spaceStateL1CTASCommandFld = {
      name: "space_State_L1CTA_S_Command",
      label: this.labels.HubLabelSecondaryCommand,
      value: space_State_L1CTA_S_Command,
      placeholder: this.labels.HubLabelSecondaryCommandPlaceholder,
      onchange: this.change,
    }

    const spaceStateL1CTATFld = {
      name: "space_State_L1CTA_T",
      label: this.labels.HubLabelTertiaryLabel,
      value: space_State_L1CTA_T,
      mandatory: true,
      placeholder: this.labels.HubLabelTertiaryPlaceholder,
      onchange: this.change,
    }

    const spaceStateL1CTATCommandFld = {
      name: "space_State_L1CTA_T_Command",
      label: this.labels.HubLabelTertiaryCommand,
      value: space_State_L1CTA_T_Command,
      mandatory: true,
      placeholder: this.labels.HubLabelTertiaryCommandPlaceholder,
      onchange: this.change,
    }

    const spaceStateValue0Fld = {
      name: "space_State_Value0",
      label: this.labels.HubLabelvalue0Label,
      value: space_State_Value0,
      placeholder: this.labels.HubLabelValue0Placeholder,
      onchange: this.change,
    }

    const spaceStateValue1Fld = {
      name: "space_State_Value1",
      label: this.labels.HubLabelvalue1Label,
      value: space_State_Value1,
      placeholder: this.labels.HubLabelValue1Placeholder,
      onchange: this.change,
    }

    const spaceStateIdFld = {
      name: "space_State_Id",
      label: this.labels.HubLabelstateId,
      value: space_State_Id,
      mandatory: true,
      placeholder: this.labels.HubLabelStateIdPlaceholder,
      onchange: this.change,
    }

    const spaceStateNameFld = {
      name: "space_State_Name",
      label: this.labels.HubLabelStateName,
      value: space_State_Name,
      mandatory: true,
      placeholder: this.labels.HubLabelStateNamePlaceholder,
      onchange: this.change,
    }

    const spaceStateBodyFld = {
      name: "space_State_Body",
      label: this.labels.HubLabelBodyText,
      value: space_State_Body,
      mandatory: true,
      placeholder: this.labels.HubLabelBodyTextPlaceholder,
      onchange: this.change,
    }

    const spaceStatePreHeaderFld = {
      name: "space_State_Pre_Header",
      label: this.labels.HubLabelPreHeaderText,
      value: space_State_Pre_Header,
      placeholder: this.labels.HubLabelPreHeaderTextPlaceholder,
      onchange: this.change,
    }

    const spaceStateTextFld = {
      name: "space_State_Text",
      label: this.labels.HubLabelLabelText,
      value: space_State_Text,
      mandatory: true,
      placeholder: this.labels.HubLabelLabelPlaceholder,
      onchange: this.change,
    }

    const spaceStateUIColorFld = {
      name: "space_State_UI_Colour",
      label: this.labels.HubLabelColour,
      value: space_State_UI_Colour,
      mandatory: true,
      placeholder: this.labels.HubLabelColourPlaceholder,
      onchange: this.change,
    }

    const spaceStateAlternateFld = {
      name: "space_State_Alternate",
      label: this.labels.HubLabelAlternate,
      value: space_State_Alternate,
      placeholder: this.labels.HubLabelAlternatePlaceholder,
      onchange: this.change,
    }

    const spaceStateShowFaultFld = {
      name: "space_State_Show_Fault",
      label: this.labels.HubLabelShowFault,
      value: space_State_Show_Fault,
      mandatory: true,
      placeholder: this.labels.HubLabelShowFaultLabelPlaceholder,
      onchange: this.change,
    }

    const spaceStateShowControlsFld = {
      name: "space_State_Show_Controls",
      label: this.labels.HubLabelShowControls,
      value: space_State_Show_Controls,
      mandatory: true,
      placeholder: this.labels.HubLabelShowControlsLabelPlaceholder,
      onchange: this.change,
    }

    const spaceStateShowInfoFld = {
      name: "space_State_Show_Info",
      label: this.labels.HubLabelShowInfo,
      value: space_State_Show_Info,
      mandatory: true,
      placeholder: this.labels.HubLabelShowInfoLabelPlaceholder,
      onchange: this.change,
    }

    const spaceStateShowScheduleFld = {
      name: "space_State_Show_Schedule",
      label: this.labels.HubLabelShowSchedule,
      value: space_State_Show_Schedule,
      mandatory: true,
      placeholder: this.labels.HubLabelSchedulePlaceholder,
      onchange: this.change,
    }

    const spaceStateShowBookingFld = {
      name: "space_State_Show_Booking",
      label: this.labels.HubLabelShowBooking,
      value: space_State_Show_Booking,
      mandatory: true,
      placeholder: this.labels.HubLabelShowBookingPlaceholder,
      onchange: this.change,
    }

    const spaceStateShowCustomFld = {
      name: "space_State_Show_Custom",
      label: this.labels.HubLabelShowCustom,
      value: space_State_Show_Custom,
      mandatory: true,
      placeholder: this.labels.HubLabelShowCustomPlaceholder,
      onchange: this.change,
    }

    const spaceStateValidationFieldFld = {
      name: "space_State_Validation_Field",
      label: this.labels.HubLabelValidationField,
      value: space_State_Validation_Field,
      placeholder: this.labels.HubLabelValidationFieldPlaceholder,
      onchange: this.change,
    }

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabProperties,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        },
        {
          title: this.labels.HubTabCallToAction,
          tabNo: 1,
          active: this.state.activePage === 1 ? true : false,
        },
        {
          title: this.labels.HubTabVisualSettings,
          tabNo: 2,
          active: this.state.activePage === 2 ? true : false,
        }
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.space_State_Value1,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.space_State_Value1,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/portfolio-setup-spaces/edit/" + this.state.nodeId + "/Building/10") },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }
    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
          <div className="rightPanel">
            <Submenu {...props} />
            <div className="rightPanel-main-content-form">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}
              <div className="form-panel">
                {this.state.activePage === 0 && (
                  <div className="main-content">
                    {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                    {/* <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubLabelSpaceStatesl}</Link></div> */}
                    <div className="form-panel-heading">
                      <div className="form-panel-breadcrumbs">
                        <div className="breadcrumbs">
                          {this.state.id === 0 ?
                            <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubLabelSpaceStatesl}</Link>
                            :
                            <div>
                              <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.spaceFilter }}>{this.labels.HubLabelSpaces}</Link>
                              {this.state.showOrganisation && this.state.organisationState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.organisationPath }} onClick={() => this.linkPath('Root')}> / {this.state.organisationState}</Link>)}
                              {this.state.showRegion && this.state.regionState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.regionPath }} onClick={() => this.linkPath('Region')}> / {this.state.regionState}</Link>)}
                              {this.state.showBuilding && this.state.buildingState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.buildingPath }} onClick={() => this.linkPath('Building')}> / {this.state.buildingState}</Link>)}
                              {this.state.showFloor && this.state.floorState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.floorPath }} onClick={() => this.linkPath('Floor')}> / {this.state.floorState}</Link>)}
                              {this.state.showZone && this.state.zoneState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.zonePath }} onClick={() => this.linkPath('Zone')}> / {this.state.zoneState}</Link>)}
                              {this.state.showRoom && this.state.roomState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.roomState }} onClick={() => this.linkPath('Spaces')}> / {this.state.roomState}</Link>)}
                              {this.state.showDesk && this.state.deskState != "" && (<Link className="breadcrumbs" to={{ pathname: this.state.deskState }} onClick={() => this.linkPath('Desk')}> / {this.state.deskState}</Link>)}
                            </div>
                          }

                        </div>
                        <div className="page-title mb-20"><h1>{this.state.displayName}</h1></div>
                      </div>
                    </div>
                    <div className="height-control">
                      {this.state.loading || (
                        <div className="card card-scroller shadow-none">
                          <div className="p-45">
                            <form>

                              <div className="row" >
                                <div className="col-6 pr-2"><Inputbox {...spaceStateIdFld} /> </div>
                                <div className="col-6"><Inputbox {...spaceStateNameFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...spaceStatePreHeaderFld} /></div>
                                <div className="col-6"><Inputbox {...spaceStateHeaderFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-12"><Inputbox {...spaceStateBodyFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-3 pr-2 input-controls"><Inputbox {...spaceStateTextFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...spaceStateIconFld} /> </div>

                                <div className="col-3 pr-2 input-controls"><Inputbox {...spaceStateUIColorFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...spaceStateAlternateFld} /> </div>
                              </div>

                            </form>
                          </div>
                        </div>)}
                    </div>
                  </div>
                )}

                {this.state.activePage === 1 && (
                  <div className="main-content">
                    {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                    <div className="form-panel-heading">
                      <div className="form-panel-breadcrumbs">
                        <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubLabelSpaceStatesl}</Link></div>
                        <div className="page-title mb-20"><h1>{this.state.displayName}</h1></div>
                      </div>
                    </div>
                    <div className="height-control">
                      {this.state.loading || (
                        <div className="card card-scroller shadow-none">
                          <div className="p-45">
                            <form>

                              <div className="row" >
                                <div className="col-6 pr-2"><Inputbox {...spaceStateL1CTAPFld} /> </div>
                                <div className="col-6"><Inputbox {...spaceStateL1CTAPCommandFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...spaceStateL1CTASFld} /></div>
                                <div className="col-6"><Inputbox {...spaceStateL1CTASCommandFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...spaceStateL1CTATFld} /></div>
                                <div className="col-6"><Inputbox {...spaceStateL1CTATCommandFld} /></div>
                              </div>

                              <div className="row" >
                                <div className="col-6 pr-2"><Inputbox {...spaceStateValue0Fld} /> </div>
                                <div className="col-6"><Inputbox {...spaceStateValue1Fld} /></div>
                              </div>

                            </form>
                          </div>
                        </div>)}
                    </div>
                  </div>
                )}

                {this.state.activePage === 2 && (
                  <div className="main-content">
                    {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                    <div className="form-panel-heading">
                      <div className="form-panel-breadcrumbs">
                        <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubLabelSpaceStatesl}</Link></div>
                        <div className="page-title mb-20"><h1>{this.state.displayName}</h1></div>
                      </div>
                    </div>
                    <div className="height-control">
                      {this.state.loading || (
                        <div className="card card-scroller shadow-none">
                          <div className="p-45">
                            <form>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...spaceStateShowSpaceFld} /> </div>
                                <div className="col-6"><Inputbox {...spaceStateShowFaultFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...spaceStateShowControlsFld} /> </div>
                                <div className="col-6"><Inputbox {...spaceStateShowInfoFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...spaceStateShowScheduleFld} /> </div>
                                <div className="col-6"><Inputbox {...spaceStateShowBookingFld} /></div>
                              </div>

                              <div className="row">
                                <div className="col-6 pr-2"><Inputbox {...spaceStateShowCustomFld} /> </div>
                                <div className="col-6"><Inputbox {...spaceStateValidationFieldFld} /></div>
                              </div>

                            </form>
                          </div>
                        </div>)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="rightPanel-main-button-group">
              <div>
                <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                {(this.state.id !== null && this.state.isDeleteRight === false) && (
                  <button className="button-tertiary-disable mr-2" disabled={true}>{this.labels.HubButtonDelete}</button>
                )}
                {(this.state.id !== null && this.state.isDeleteRight === true) && (
                  <button className="button-tertiary mr-2" onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                )}
              </div>
              <div>
                {this.state.isUpdateRight === false &&
                  <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                }
                {this.state.isUpdateRight === true &&
                  <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                }
              </div>
            </div>
          </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(SpaceStatesedit);