import { Box, Grid, Paper, PaperProps, styled, Typography } from '@mui/material';
import moment from 'moment';
import { Component } from 'react';
import IbssChip from './IbssChip';

type IbssPaperProps = PaperProps & {
    imageSrc: string,
    notificationHeading: string,
    notificationSubDescription: string,
    notificationSubClassification: string,
    availableFrom: string,
    priority: number,
    read: boolean,
}

const StyledPaper = styled(Paper)<IbssPaperProps>(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: '24px',
    backgroundColor: 'var(--ui-background-alternate)'
}));

class IbssNotificationCard extends Component<IbssPaperProps> {
    render() {
        const {
            imageSrc,
            notificationHeading,
            notificationSubDescription,
            notificationSubClassification,
            availableFrom,
            priority,
            read,
        } = this.props;
        console.log("this.props",this.props)

        // get Date format
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let availableDate = ""
        let dateFormat = new Date(availableFrom);

        availableDate = moment(dateFormat).format('Do MMM');
        return (
            <div className="">
            <StyledPaper {...this.props} sx={{ cursor: "pointer" }}>
                    <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex text-left">
                    <div className="line-height-1 notification-grid-image">
                        <img src={imageSrc ? imageSrc : 'images/FlexNotificationImage.svg'} />
                    </div>
                    <div className="ml-24">
                        <Typography className="text-ui-md" variant='h6'>
                            {notificationHeading}
                        </Typography>
                        <Typography variant='body2' className="card-userPref-text mt-8">
                            {notificationSubDescription}
                        </Typography>
                        <Typography variant='body2' className="text-info-xs mt-8">
                            {notificationSubClassification} | {availableDate}
                        </Typography>
                    </div>
                    </div>
                    <div className="d-flex align-items-center">
                    {priority === 1 && (
                        <Grid xs={2} className="mr-24">
                            <IbssChip bgColor={`var(--ui-error-pastel)`} textColor={`var(--ui-error)`} label="High" />
                        </Grid>
                    )}
                    {!read && (
                        <Grid xs={priority === 1 ? 2 : 4}>
                            <Box className="notification-circle-blue" />
                        </Grid>
                    )}
                    </div>
                    </div>
            </StyledPaper>
            </div>
        )
    }
}

export default IbssNotificationCard;
