import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IUserPreferences } from "../UserPreferenceRepository";

export class DeleteBookingPolicyEndpoint implements IDeleteBookingPolicyEndpoint
{
    public async execute(nodeId: number, bookingPolicyId: string): Promise<void>
    {
        try
        {
            const response: AxiosResponse<IUserPreferences> = await axios.delete(`${apis.dataEntryApiV2}/${nodeId}/BookingPolicies/${bookingPolicyId}`);
            if (response.status !== 200)
            {
                throw response;
            }
    
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteBookingPolicyEndpoint
{
    execute(nodeId: number, bookingPolicyId: string): Promise<void>;
}
