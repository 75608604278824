import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import "../../styles/css/form.scss";
import Inputbox from "../../Components/uicomponents/Inputbox";
import SelectBox from "../../Components/uicomponents/SelectBox";
import ConfirmModal from "../../Components/uicomponents/ConfirmModal";
import Submenu from "../../Components/SubMenu";
import { Link } from "react-router-dom";
import { runInThisContext } from "vm";
import { create } from "domain";
import LoadingOverlay from "../../Components/LoadingOverlay"
import { convertApiResponseObjectToLower, convertObjectVariableFirstLatterToUpperCase } from "../../Common/Helper"
import { appContext } from "../../AppContext";

class BuildingKPIDetails extends Component {
  private labels = appContext().labels;
  private local = appContext().localStorageProvider;

  state = {
    isLoadingToSave: false,
    filter: "",
    loading: false,
    activePage: 0,
    updates: false,
    nodeId: null,
    title: "",
    id: null,
    kpiType: null,
    units: null,
    label: null,
    maximumCount: null,
    buildingKPI_Limit1: null,
    buildingKPI_Limit2: null,
    buildingKPI_Text0: null,
    buildingKPI_Text1: null,
    buildingKPI_Text2: null,
    buildingKPI_Value: null,
    valueURI: null,
    icon: null,
    organisationState: "",
    organisationPath: "",
    organisationId: null,
    showOrganisation: false,
    regionState: "",
    regionPath: "",
    regionId: null,
    showRegion: false,
    buildingState: "",
    buildingPath: "",
    buildingId: null,
    showBuilding: false,
    floorState: "",
    floorPath: "",
    floorId: null,
    showFloor: false,
    zoneState: "",
    zonePath: "",
    zoneId: null,
    showZone: false,
    roomState: "",
    roomPath: "",
    roomId: null,
    showRoom: false,
    deskState: "",
    deskPath: "",
    deskId: null,
    showDesk: false,
    spaceFilter: "",
    show: false,
    isDelete: false,
    isUpdateRight: false,
    isDeleteRight: false,
    apiVersion: 11
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    if (history.location.state === 'cancel') {
      this.setState({ activePage: 2 });
    }
    let versions = 11;
    const version = this.local.getVersion();
    if (version !== 0) {
      versions = version;
    }
    this.setState({
      spaceFilter: sessionStorage.getItem('spaceFilter'),
      apiVersion: versions
    })
    if (match.params.id !== "0" && match.params.nodeid) {
      this.getRightList();
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        id: parseInt(match.params.id),
        filter: match.params.filter
      }, () => {
        this.getDetails(this.state.id, this.state.filter);
      })
    } else {
      this.setState({
        nodeId: parseInt(match.params.nodeid),
        title: "New Building KPI",
        filter: match.params.filter,
        id: 0,
        isUpdateRight: true,
      })
    }

  }

  getRightList = () => {
    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.BuildingKPIs && res.ADMINPORTAL.BuildingKPIs.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.BuildingKPIs && res.ADMINPORTAL.BuildingKPIs.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getDetails = (id: any, filter: any) => {
    this.setState({ loading: true });
    const nodeId = this.state.nodeId;

    if (sessionStorage.getItem('showOrganisation') === "true") {
      this.setState({
        showOrganisation: true,
        organisationState: sessionStorage.getItem('organisationState'),
        orgnisationnPath: sessionStorage.getItem('orgnisationnPath'),
        organisationId: sessionStorage.getItem('organisationId')
      })
    }

    if (sessionStorage.getItem('showRegion') === "true") {
      this.setState({
        showRegion: true,
        regionState: sessionStorage.getItem('regionState'),
        regionPath: sessionStorage.getItem('regionPath'),
        regionId: sessionStorage.getItem('regionId')
      })
    }

    if (sessionStorage.getItem('showBuilding') === "true") {
      this.setState({
        showBuilding: true,
        buildingState: sessionStorage.getItem('buildingState'),
        buildingPath: sessionStorage.getItem('buildingPath'),
        buildingId: sessionStorage.getItem('buildingId')
      })
    }
    if (sessionStorage.getItem('showFloor') === "true") {
      this.setState({
        showFloor: true,
        floorState: sessionStorage.getItem('floorState'),
        floorPath: sessionStorage.getItem('floorPath'),
        floorId: sessionStorage.getItem('floorId')
      })
    }
    if (sessionStorage.getItem('showZone') === "true") {
      this.setState({
        showZone: true,
        zoneState: sessionStorage.getItem('zoneState'),
        zonePath: sessionStorage.getItem('zonePath'),
        zoneId: sessionStorage.getItem('zoneId')
      })
    }
    if (sessionStorage.getItem('showRoom') === "true") {
      this.setState({
        showRoom: true,
        roomState: sessionStorage.getItem('roomState'),
        roomPath: sessionStorage.getItem('roomPath'),
        roomId: sessionStorage.getItem('roomId')
      })
    }
    if (sessionStorage.getItem('showDesk') === "true") {
      this.setState({
        showDesk: true,
        deskState: sessionStorage.getItem('deskState'),
        deskPath: sessionStorage.getItem('deskPath'),
        deskId: sessionStorage.getItem('deskId')
      })
    }
    apis.getBuildingKPIById(nodeId, id).then((res) => {
      const newData = convertApiResponseObjectToLower(res.data)
      this.setState({
        loading: false,
        title: newData.label,
        kpiType: newData.kPIType ? newData.kPIType : newData.kpiType,
        units: newData.units,
        label: newData.label,
        maximumCount: newData.maximumCount,
        buildingKPI_Limit1: newData.limit1,
        buildingKPI_Limit2: newData.limit2,
        buildingKPI_Text0: newData.text0,
        buildingKPI_Text1: newData.text1,
        buildingKPI_Text2: newData.text2,
        buildingKPI_Value: newData.value,
        valueURI: newData.valueURI,
        icon: newData.icon
      });
    }).catch((error: any) => { });

  };

  submit = () => {
    this.setState({ isLoadingToSave: true });
    const { match }: any = this.props;
    const { nodeId, id, units, label, maximumCount, buildingKPI_Limit1,
      buildingKPI_Limit2, buildingKPI_Text0, buildingKPI_Text1, buildingKPI_Text2, buildingKPI_Value,
      valueURI, icon }: any = this.state;

    let payload = {
      "kpiType": 1,
      "units": units,
      "label": label,
      "maximumCount": parseInt(maximumCount),
      "buildingKPI_Limit1": parseInt(buildingKPI_Limit1),
      "buildingKPI_Limit2": parseInt(buildingKPI_Limit2),
      "buildingKPI_Text0": buildingKPI_Text0,
      "buildingKPI_Text1": buildingKPI_Text1,
      "buildingKPI_Text2": buildingKPI_Text2,
      "buildingKPI_Value": parseInt(buildingKPI_Value),
      "valueURI": valueURI,
      "icon": icon,
      "actionLabel": "",
      "actionURI": ""
    }

    if (this.state.apiVersion > 10) {
      // use upperCase
      payload = convertObjectVariableFirstLatterToUpperCase(payload)
    } else if (this.state.apiVersion === 10) {
      // use lowerCase
    }

    if (match.params.id === "0") {
      this.create(nodeId, payload);
    } else {
      this.update(nodeId, id, payload);
    }
  };

  create = (nodeId: any, payload: any) => {
    apis.createBuildingKPI(nodeId, payload).then((res) => {
      const { history }: any = this.props;
      this.setState({
        updates: true,
        isLoadingToSave: false,
      }, () => {
        history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/BuildingKPI/nodeid=" + nodeId + "/id=" + res.data.buildingKPIId);
        window.location.reload();
      })
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false });
    });
  }

  update = (nodeId: any, id: any, payload: any) => {
    const { history }: any = this.props;
    const { updates }: any = this.state;
    if (updates) {
      apis.updateBuildingKPIById(nodeId, id, payload).then((res) => {
        this.setState({ isLoadingToSave: false });
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/11");
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false });
      });
    } else {
      this.setState({ isLoadingToSave: false });
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/11");
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };
  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }
  delete = () => {
    const { match }: any = this.props;
    const id = match.params.id;
    const nodeId = this.state.nodeId
    if (this.state.isDelete) {
      apis.deleteBuildingKPIById(nodeId, id).then((res) => {
        const { history }: any = this.props;
        history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/11");
      }).catch((error: any) => { });
    }
  };
  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates, nodeId } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      history.push("/portfolio-setup-spaces/edit/" + nodeId + "/" + this.state.filter + "/11");
    }
  }

  emptyLocalstorage = (filter: any) => {
    if (filter === "Root") {
      // console.log("Root")
      sessionStorage.setItem('showOrganisation', "");
      sessionStorage.setItem('organisationState', "");
      sessionStorage.setItem('orgnisationnPath', '');
      sessionStorage.setItem('organisationId', "");
    }
    if (filter === "Root" || filter === "Region") {
      // console.log("Region")
      sessionStorage.setItem('showRegion', "");
      sessionStorage.setItem('regionState', "");
      sessionStorage.setItem('regionPath', '');
      sessionStorage.setItem('regionId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building") {
      // console.log("Building")
      sessionStorage.setItem('showBuilding', "");
      sessionStorage.setItem('buildingState', "");
      sessionStorage.setItem('buildingPath', '');
      sessionStorage.setItem('buildingId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor") {
      // console.log("Floor")
      sessionStorage.setItem('showFloor', "");
      sessionStorage.setItem('floorState', "");
      sessionStorage.setItem('floorPath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone") {
      // console.log("Zone")
      sessionStorage.setItem('showZone', "");
      sessionStorage.setItem('zoneState', "");
      sessionStorage.setItem('zonePath', '');
      sessionStorage.setItem('floorId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces") {
      // console.log("Spaces")
      sessionStorage.setItem('showRoom', "");
      sessionStorage.setItem('roomState', "");
      sessionStorage.setItem('roomPath', '');
      sessionStorage.setItem('roomId', "");
    }
    if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Spaces" || filter === "Desk") {
      // console.log("Desk")
      sessionStorage.setItem('showDesk', "");
      sessionStorage.setItem('deskState', "");
      sessionStorage.setItem('deskPath', '');
      sessionStorage.setItem('deskId', "");
    }
  }


  linkPath = (data: any) => {
    const { history }: any = this.props;

    if (data === "Root") {
      this.setState({
        showOrganisation: false,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Root");
        history.push("/portfolio-setup-spaces/edit/" + this.state.organisationId + "/Root");
        // this.getDetails(this.state.organisationId, 'Root');
      })
    }

    if (data === "Region") {
      this.setState({
        showOrganisation: true,
        showRegion: false,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Region");
        history.push("/portfolio-setup-spaces/edit/" + this.state.regionId + "/Region");
        // this.getDetails(this.state.regionId, 'Region');
      })

    }

    if (data === "Building") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: false,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Building");
        history.push(this.state.buildingPath);
        // this.getDetails(this.state.buildingId, 'Building');
      })

    }

    if (data === "Floor") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: false,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Floor");
        history.push(this.state.floorPath);
        // this.getDetails(this.state.floorId, 'Floor');
      })

    }

    if (data === "Zone") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: false,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Zone");
        history.push(this.state.zonePath);
        // this.getDetails(this.state.zoneId, 'Zone');
      })
    }

    if (data === "Spaces") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: false,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Spaces");
        history.push(this.state.roomPath);
        // this.getDetails(this.state.roomId, 'Spaces');
      })
    }

    if (data === "Desk") {
      this.setState({
        showOrganisation: true,
        showRegion: true,
        showBuilding: true,
        showFloor: true,
        showZone: true,
        showRoom: true,
        showDesk: false,
        reload: false
      }, () => {
        this.emptyLocalstorage("Desk");
        history.push(this.state.deskPath);
        // this.getDetails(this.state.deskId, 'Desk');
      })
    }

  }

  render() {
    const { kpiType, units, label, maximumCount, buildingKPI_Limit1, buildingKPI_Limit2, buildingKPI_Text0,
      buildingKPI_Text1, buildingKPI_Text2, buildingKPI_Value, valueURI, icon }: any = this.state;
    const { history }: any = this.props;

    const labelFld = {
      name: "label",
      label: this.labels.HubLabelLabel,
      value: label,
      mandatory: true,
      placeholder: this.labels.HubLabelLabelPlaceholder,
      onchange: this.change,
    }

    const kpiTypeFld = {
      name: "kpiType",
      label: this.labels.HubLabelType,
      value: kpiType,
      mandatory: true,
      onchange: (value: any) => {
        this.setState({
          kpiType: value,
          updates: true
        });
      },
      type: "option",
      options: [
        { title: this.labels.HubLabelSlider, value: "NumberSlider" },
        { title: this.labels.HubLabelTextIndicatorSlider, value: "TextIndicatorwithSlider" },
        { title: this.labels.HubLabelNumericIndicator, value: "HubLabelNumericIndicator" },
        { title: this.labels.HubLabelTextIndicator, value: "TextIndicator" }
      ],
      placeholder: this.labels.HubLabelTypeLabelPlaceholder,
    };

    const iconFld = {
      name: "icon",
      label: this.labels.HubLabelIcon,
      value: icon,
      mandatory: true,
      placeholder: this.labels.HubLabelIconLabelPlaceHolder,
      onchange: this.change,
    }

    const buildingKPI_Text0Fld = {
      name: "buildingKPI_Text0",
      label: this.labels.HubLabelText0,
      value: buildingKPI_Text0,
      placeholder: this.labels.HubLabelText0PlaceHolder,
      onchange: this.change,
    }

    const buildingKPI_Text1Fld = {
      name: "buildingKPI_Text1",
      label: this.labels.HubLabelText1,
      value: buildingKPI_Text1,
      placeholder: this.labels.HubLabelText1PlaceHolder,
      onchange: this.change,
    }

    const buildingKPI_Text2Fld = {
      name: "buildingKPI_Text2",
      label: this.labels.HubLabelText2,
      value: buildingKPI_Text2,
      placeholder: this.labels.HubLabelText2PlaceHolder,
      onchange: this.change,
    }

    const valueURIFld = {
      name: "valueURI",
      label: this.labels.HubLabelValueURI,
      value: valueURI,
      mandatory: true,
      placeholder: this.labels.HubLabelValueURIPlaceHolder,
      onchange: this.change,
    }

    const unitsFld = {
      name: "units",
      label: this.labels.HubLabelUnits,
      value: units,
      placeholder: this.labels.HubLabelUnitsPlaceholder,
      onchange: this.change,
    }

    const buildingKPI_ValueFld = {
      name: "buildingKPI_Value",
      label: this.labels.HubLabelInitialValue,
      value: buildingKPI_Value,
      placeholder: this.labels.HubLabelInitialValuePlaceholder,
      onchange: this.change,
    }

    const maximumCountFld = {
      name: "maximumCount",
      label: this.labels.HubLabelMaximumValue,
      value: maximumCount,
      mandatory: true,
      placeholder: this.labels.HubLabelMaximumValuePlaceholder,
      onchange: this.change,
    }

    const buildingKPI_Limit1Fld = {
      name: "buildingKPI_Limit1",
      label: this.labels.HubLabelLimit1,
      value: buildingKPI_Limit1,
      mandatory: true,
      placeholder: this.labels.HubLabellimit1Placeholder,
      onchange: this.change,
    }

    const buildingKPI_Limit2Fld = {
      name: "buildingKPI_Limit2",
      label: this.labels.HubLabelLimit2,
      value: buildingKPI_Limit2,
      mandatory: true,
      placeholder: this.labels.HubLabelLimit2Placeholder,
      onchange: this.change,
    }

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabProperties,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.label,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.label,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/portfolio-setup-spaces/edit/" + this.state.nodeId + "/" + this.state.filter + "/11") },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }
    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
          <div className="rightPanel">
            <Submenu {...props} />
            <div className="rightPanel-main-content-form">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}
              <div className="form-panel">
                <div className="form-panel-heading">
                  <div className="form-panel-breadcrumbs">
                    <div className="breadcrumbs">
                      {this.state.id === 0 ?
                        <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubTabBuildingKPIs}</Link>
                        :
                        <div>
                          <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.spaceFilter }}>{this.labels.HubLabelSpaces}</Link>
                          {this.state.showOrganisation && this.state.organisationState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.organisationPath }} onClick={() => this.linkPath('Root')}> / {this.state.organisationState}</Link>)}
                          {this.state.showRegion && this.state.regionState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.regionPath }} onClick={() => this.linkPath('Region')}> / {this.state.regionState}</Link>)}
                          {this.state.showBuilding && this.state.buildingState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.buildingPath }} onClick={() => this.linkPath('Building')}> / {this.state.buildingState}</Link>)}
                          {this.state.showFloor && this.state.floorState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.floorPath }} onClick={() => this.linkPath('Floor')}> / {this.state.floorState}</Link>)}
                          {this.state.showZone && this.state.zoneState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.zonePath }} onClick={() => this.linkPath('Zone')}> / {this.state.zoneState}</Link>)}
                          {this.state.showRoom && this.state.roomState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.roomState }} onClick={() => this.linkPath('Spaces')}> / {this.state.roomState}</Link>)}
                          {this.state.showDesk && this.state.deskState !== "" && (<Link className="breadcrumbs" to={{ pathname: this.state.deskState }} onClick={() => this.linkPath('Desk')}> / {this.state.deskState}</Link>)}
                        </div>
                      }

                    </div>
                    <div className="page-title mb-20"><h1>{this.state.title}</h1></div>
                  </div>
                </div>
                {this.state.activePage === 0 && (
                  <div className="main-content">
                    {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}
                    {/* <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/edit/` + this.state.nodeId + `/Building` }}>{this.labels.HubTabBuildingKPIs}</Link></div> */}
                    <div className="height-control">
                      {this.state.loading || (
                        <div className="card card-scroller">
                          <div className="p-45">
                            <form>

                              <div className="row" >
                                <div className="col-6 pr-2"><Inputbox {...labelFld} /> </div>
                                <div className="col-6"><SelectBox {...kpiTypeFld} /> </div>
                              </div>

                              <div className="row" >
                                <div className="col-3 pr-2 input-controls"><Inputbox {...iconFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...buildingKPI_Text0Fld} /> </div>

                                <div className="col-3 pr-2 input-controls"><Inputbox {...buildingKPI_Text1Fld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...buildingKPI_Text2Fld} /> </div>
                              </div>

                              <div className="row" >
                                <div className="col-6 pr-2"><Inputbox {...valueURIFld} /> </div>
                                <div className="col-6"><Inputbox {...unitsFld} /> </div>
                              </div>

                              <div className="row" >
                                <div className="col-3 pr-2 input-controls"><Inputbox {...buildingKPI_ValueFld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...maximumCountFld} /> </div>

                                <div className="col-3 pr-2 input-controls"><Inputbox {...buildingKPI_Limit1Fld} /> </div>
                                <div className="col-3 input-controls"><Inputbox {...buildingKPI_Limit2Fld} /> </div>
                              </div>

                            </form>
                          </div>
                        </div>)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="rightPanel-main-button-group">
              <div>
                <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                {(this.state.id !== 0 && this.state.isDeleteRight === false) && (
                  <button className="button-tertiary-disable mr-2" disabled={true} onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                )}
                {(this.state.id !== 0 && this.state.isDeleteRight === true) && (
                  <button className="button-tertiary mr-2" onClick={() => this.isDelete()} >{this.labels.HubButtonDelete}</button>
                )}
              </div>
              <div>
                {this.state.isUpdateRight === false &&
                  <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                }
                {this.state.isUpdateRight === true &&
                  <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                }
              </div>
            </div>
          </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(BuildingKPIDetails);