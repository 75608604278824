import { Component } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NotificationListProps } from '../../../Components/uicomponents/models/FlexNotificationProps';
import IbssSvgIcon from '../../../Components/uicomponents/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons'; 
import { appContext } from '../../../AppContext';
import { DateHelper } from '../../../Common/DateHelper';

class NotificationDetails extends Component<{ details: NotificationListProps, removeNotificationDetails: () => void }> {
  render() {
    // console.log('prop:11', this.props.details);
    const { details } = this.props;

    // get Date format
    let availableDate = ""

    availableDate = DateHelper.fromIsoToDefaultFormat(details?.Available_From.toString(), "dd MMM");

    return (
      <>
        <Box className="notification-right-pannel">
          <Box>
            <img className="notification-details-img" src={`${details.ImageURI ? details.ImageURI : 'images/FlexNotificationImage.svg'}`} />
          </Box>
          <Box className="notification-details-card">
            <div className="d-flex justify-content-between">
              <Typography className="text-info-xs">
                {details.Subclassification} | {availableDate}
              </Typography>
              <span className="text-ui-md pointer" onClick={() => this.props.removeNotificationDetails()}>
                <IbssSvgIcon>{Icons.CloseIcon}</IbssSvgIcon>
              </span>
            </div>
            <Typography className="card-userPref-text" variant='h6'>
              {details.Notification_Title}
            </Typography>
            <Typography className="card-userPref-text display-block" variant='body2' dangerouslySetInnerHTML={{ __html: details.Notification_Message }} />
            {/* <Link className="pointer">
             {this.labels.HubLabelFindOut}
            </Link> */}
          </Box>
        </Box>
      </>
    )
  }
}

export default NotificationDetails;
