import React, { Component } from "react";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssButton from "../../../Components/uicomponents/IbssButton";
import { IUserPreferences } from "../../../Providers.Api/UserPreferenceRepository";
import OnBoardingCreatingPIN from "./IBSSPIN";

export default class OnBoardingSetUpaPIN extends Component<IOnBoardingSetUpaPIN, State>
{
    private labels = appContext().labels;
    private incrementActiveStepProp;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;

    constructor(props: IOnBoardingSetUpaPIN) {
        super(props);
        this.incrementActiveStepProp = props.incrementActiveStep;
        this.state = new State();
    }

    public componentDidMount() {
    } 
    submitBuildingPIN = async () => {
        const payload =
        {
            ...this.local.getUserPreferences(),
            SecurityPrefs: {
                Pin: this.state.PIN,
            }
        }
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.incrementActiveStepProp();
            }
        }
        catch { }
    }

    public render(): JSX.Element {
       
        return (<>
        <div className="px-50">
            <div className="container">
                <h2 className="ibss-modal-title mb-10"> {this.labels.HubLabelSetupaPIN}</h2>
                <p className="ibss-modal-text-md"> {this.labels.HubLabelSetupaPINPara}:</p>
                <div className="ibss-modal-text-md text-left">{this.labels.HubLabelSetupaPIN} &#8758; </div>
                <div className="d-flex justify-content-center my-16">
                 <OnBoardingCreatingPIN
                    valueLength={4}
                    value={this.props.PIN}
                    onChange={(e) => {this.props.PinChange(e); this.setState({ PIN: e })}}
                />
                </div>
            </div>
            <p className="ibss-modal-text-md" style={{ color: `var(--ui-text-alternate)` }}>
                {this.labels.HubLabel4DigitPIN}
                <strong> {this.labels.HubLabelPINMenu} </strong></p>
            <IbssButton
                color="primary" variant="contained"
                onClick={() => { this.submitBuildingPIN(); }}
            > {this.labels.HubButtonNext} </IbssButton>
            </div>
        </>);
    }

}



class State {
    PIN = ""
}
export interface IOnBoardingSetUpaPIN {
    incrementActiveStep?: any;
    PIN: string;
    PinChange: any;
    userPrefrences: IUserPreferences;
}

