import { DateTime, MinuteNumbers, WeekdayNumbers } from "luxon";
import { Component } from "react";

// temporary test runner until we get something better in place
export default class TestRunner extends Component<any, IState>
{
    constructor(props: any)
    {
        super(props);
        this.state = { results: new Array<ITestResult>() }
    }

    public async componentDidMount(): Promise<void>
    {
        let results = new Array<ITestResult>;
        for (let i = 0; i < this.tests.length; i++)
        {
            let test = this.tests[i];
            let name = test.toString();

            try
            {
                let result = await test();
                if (result)
                {
                    results.push({ passed: true, name: name });
                }
                else
                {
                    results.push({ passed: false, name: name });
                }
            }
            catch
            {
                results.push({ passed: false, name: name });
            }
        }
        this.setState({ results: results });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <style>
                {`
                    td + td { border-left: solid silver 1px; }
                    td { padding: 5px 20px; }
                `}
                </style>
                <table>
                    <thead>
                        <tr>
                            <td>Pass / Fail</td>
                            <td>Test Name</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.results.map(i => (
                        <tr key={i.name}>
                            <td>{i.passed ? "PASS" : "FAIL"}</td>
                            <td>{i.name}</td>
                        </tr>))}
                    </tbody>
                </table>
            </>
        );
    }

    private tests: Array<() => Promise<boolean>> =
    [
        // snap to minute
        () => this.SnapToMinute_ReturnsAnInvalidTime_WhenTheTimeIsInvalid(),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], 1, false, false, "2023-03-01T09:00", "2023-03-01T09:00"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], 1, false, false, "2023-03-01T09:15", "2023-03-01T09:30"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], 1, false, false, "2023-03-01T09:45", "2023-03-01T10:00"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], 1, false, false, "2023-03-01T23:45", "2023-03-02T00:00"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], 1, true, false, "2023-03-01T23:45", "2023-03-01T00:00"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], 1, true, true, "2023-03-01T23:45", "2023-03-02T00:00"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([15, 45], 1, true, true, "2023-03-01T23:50", "2023-03-01T00:15"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], -1, false, false, "2023-03-01T09:15", "2023-03-01T09:00"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([15, 45], -1, false, false, "2023-03-01T09:00", "2023-03-01T08:45"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([15, 45], -1, false, false, "2023-03-01T00:00", "2023-02-28T23:45"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([15, 45], -1, true, false, "2023-03-01T00:00", "2023-03-01T23:45"),
        () => this.SnapToMinute_ReturnsTheExpectedResult([0, 30], -1, false, false, "2023-03-01T17:30", "2023-03-01T17:30"),

        // snap to day
        () => this.SnapToDay_ReturnsTheExpectedTime(2, 6, "2022-08-01T09:00", "2022-08-02T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(2, 6, "2022-08-02T09:00", "2022-08-02T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(2, 6, "2022-08-04T09:00", "2022-08-04T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(2, 6, "2022-08-06T09:00", "2022-08-06T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(2, 6, "2022-08-07T09:00", "2022-08-09T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(6, 2, "2022-08-01T09:00", "2022-08-01T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(6, 2, "2022-08-02T09:00", "2022-08-02T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(6, 2, "2022-08-04T09:00", "2022-08-06T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(6, 2, "2022-08-06T09:00", "2022-08-06T09:00"),
        () => this.SnapToDay_ReturnsTheExpectedTime(6, 2, "2022-08-07T09:00", "2022-08-07T09:00"),
    ];

    private async SnapToMinute_ReturnsAnInvalidTime_WhenTheTimeIsInvalid(): Promise<boolean>
    {
        // arrange
        let time = DateTime.invalid("test", "test");

        // act
        let result = time.snapToMinute([0, 30]);

        // assert
        return (result != time && !result.isValid);
    }

    private async SnapToMinute_ReturnsTheExpectedResult(validMinutes: MinuteNumbers[], direction: number, preserveDate: boolean, allowStartOfNextDay: boolean, currentTime: string, expectedResult: string): Promise<boolean>
    {
        // arrange
        let time = DateTime.fromISO(currentTime);
        let expectedResultAsTime = DateTime.fromISO(expectedResult);

        // act
        let result = time.snapToMinute(validMinutes, { direction: direction, preserveDate: preserveDate, allowStartOfNextDay: allowStartOfNextDay });

        // assert
        return (result != time && result.equals(expectedResultAsTime));
    }

    private async SnapToDay_ReturnsTheExpectedTime(firstAllowedDay: WeekdayNumbers, lastAllowedDay: WeekdayNumbers, currentTime: string, expectedResult: string): Promise<boolean>
    {
        // arrange
        let time = DateTime.fromISO(currentTime);

        // act
        let result = time.snapToDay(firstAllowedDay, lastAllowedDay);

        // assert
        return (result != time && result.equals(DateTime.fromISO(expectedResult)));
    }
}

interface IState
{
    results: ITestResult[];
}

interface ITestResult
{
    passed: boolean;
    name: string;
}
