import { Component } from "react";
import "../../styles/css/table.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray } from "../../Common/Helper";
import Spinner from "../../Components/Spinner";
import TablePanelInfo from "../../Components/table/TablePanelInfo"
import { Link } from "react-router-dom";
import { IPartialAppState, appContext } from "../../AppContext";
import { DateTime } from "luxon";

class FacilityNotifications extends Component {
  private alert = appContext().alert;
  private appState = appContext().state;
  private labels = appContext().labels;
  private session = appContext().sessionStorageProvider;
  state = {
    isLoading: false,
    NotificationList: [],
    facilityDataSort: { columnName: "", direction: "" },
    selectedBuildingId: this.appState.buildingId.toString(),
  };

  public async componentDidMount() {
    const { history, match }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId }, this);
    if (this.session.getBuildingId() !== match.params.buildingid) {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.handleGetNotificationData();
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
      if (state.buildingId != undefined)
      {
          this.setState({ selectedBuildingId: state.buildingId.toString() });
          history.push(match.path.replace(":buildingid", state.buildingId))
      }
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void {
    if(prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.handleGetNotificationData();
    }
  }

  handleGetNotificationData = async () => {
    this.setState({ isLoading: true })
    try {
      const response: any = await apis.getFacilityNotification(this.session.getBuildingId())
      const responseData = response.data

      const newResponseData = responseData && responseData[0] && responseData.map((item: any) => {
        let newItem = { ...item }
        newItem.isChecked = false

        newItem.table_Notification_Title = <div onClick={() => this.editNotification(item)}>{item.Notification_Title}</div>
        newItem.table_Notification_Priority = <div onClick={() => this.editNotification(item)}>{item.Notification_Priority}</div>
        newItem.table_Classification = <div onClick={() => this.editNotification(item)}>{item.Classification}</div>
        newItem.table_Subclassification = <div onClick={() => this.editNotification(item)}>{item.Subclassification}</div>
        newItem.table_Available_From = <div onClick={() => this.editNotification(item)}>{DateTime.fromISO(item.Available_From).toFormat("dd/MM/yyyy HH:mm")}</div>
        newItem.table_Expiry_Date = <div onClick={() => this.editNotification(item)}>{DateTime.fromISO(item.Expiry_Date).toFormat("dd/MM/yyyy HH:mm")}</div>
        return newItem
      })

      this.setState({ isLoading: false, NotificationList: newResponseData ? newResponseData : [] })
    } catch (error: any) {
      this.setState({
        isLoading: false,
        spaces: [{ id: 0, name: "" }],
      })
    }
  }

  handleClickCheckBox = (e: any, checkItem: any) => {
    const newNotificationList = this.state.NotificationList.map((item: any) => {
      if (checkItem.Notification_Id === item.Notification_Id) {
        let newItem = { ...item }
        newItem.isChecked = !item.isChecked
        return newItem
      }
      return item
    })
    this.setState({ NotificationList: newNotificationList })
  }

  editNotification = (item: any) => {
    const { history }: any = this.props;
    history.push(`/operational-services-notifications/${this.session.getBuildingId()}/notifications/${item.Notification_Id}`)
  }

  handleFacilityDataSort = (e: any) => {
    this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.NotificationList, e.column, e.direction)
    this.setState({ NotificationList: newArray })
  }

  checkIsButtonDisabled = () => {
    let checkedCount = 0
    this.state.NotificationList.forEach((item: any) => {
      if (item.isChecked === true) {
        checkedCount++
      }
    });

    const buttonDisabledObject = {
      isEditButtonDisabled: checkedCount === 1 ? false : true,
      isDeleteButtonDisabled: checkedCount > 0 ? false : true,
    }

    return buttonDisabledObject;
  }

  handleEditNotification = () => {
    const { history, match }: any = this.props;
    this.state.NotificationList.forEach((item: any) => {
      if (item.isChecked === true) {
        history.push(`/operational-services-notifications/${this.session.getBuildingId()}/notifications/${item.Notification_Id}`)
      }
    });
  }


  handleDeleteNotification = async () => {
    this.setState({ isLoading: true });
    this.alert.show(this.labels.HubLabelLoadingText, "");
    let numOfItemsUpdated = 0;

    await Promise.all(this.state.NotificationList.map(async (item: any) => {
      if (item.isChecked === true) {
        try {
          const response = await apis.deleteSpecificFacilityNotification(this.session.getBuildingId(), item.Notification_Id)
          numOfItemsUpdated++;
          return response
        } catch (error: any) {
          return "error occured"
        }
      } else {
        return;
      }
    }));

    this.setState({ isLoading: false });
    this.alert.show(this.labels.HubLabelComplete, `${numOfItemsUpdated}  notifications deleted.`);
    await this.handleGetNotificationData()
    return;
  }


  render() {
    const { isEditButtonDisabled, isDeleteButtonDisabled } = this.checkIsButtonDisabled()

    let newNotificationList: any = []
    this.state.NotificationList && this.state.NotificationList[0] && this.state.NotificationList.forEach((item: any, index: any) => {
      let newItem = { ...item }
      newItem.check = <label className="newCheckBox">
        <input type="checkbox" className="checkBoxHide" name={item.Notification_Id} id={item.Notification_Id} checked={item.isChecked} onChange={(e: any) => { this.handleClickCheckBox(e, item) }} />
        <span className="geekmark"></span>
      </label>
      newNotificationList.push(newItem)
    })


    const tableData = {
      columns: [
        {
          label: "",
          field: "check",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelTitle,
          field: "table_Notification_Title",
          sort: this.state.facilityDataSort.columnName == "table_Notification_Title" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelPriorityLabel,
          field: "table_Notification_Priority",
          sort: this.state.facilityDataSort.columnName == "table_Notification_Priority" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelClass,
          field: "table_Classification",
          sort: this.state.facilityDataSort.columnName == "table_Classification" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelSubClass,
          field: "table_Subclassification",
          sort: this.state.facilityDataSort.columnName == "table_Subclassification" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelFrom,
          field: "table_Available_From",
          sort: this.state.facilityDataSort.columnName == "table_Available_From" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelExpiry,
          field: "table_Expiry_Date",
          sort: this.state.facilityDataSort.columnName == "table_Expiry_Date" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: newNotificationList ? newNotificationList : []
    };

    return (
      <>         
            <div className="rightPanel-main-content">
              <div className="table-panel">
                <TablePanelInfo
                  tableHeading={this.labels.HubLabelNotifications}
                  iconSrc="/images/Sidebar_Icons/Light_theme/Notification.svg"
                />
                <div className="table-panel-content">
                  <Link to={{ pathname: `/operational-services-notifications/` + this.session.getBuildingId() + `/notifications/0`, }}>
                    <button className="button-primary">{this.labels.HubButtonAdd}</button>
                  </Link>
                </div>
                <div className="position-relative">
                  {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                  <MDBDataTable className="standardTable table-noSubmenue-withBottomButton tableNotifications" searchLabel={this.labels.HubLabelMySearchLabel} disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small onSort={this.handleFacilityDataSort} data={tableData} />
                </div>
              </div>
              <div className="main-button-content">
                <div className="buttons-group" >
                  <div className="col-12 div-flex">
                    <div className="col-12">
                      <button className="button-secondary mr-2" disabled={isDeleteButtonDisabled} onClick={this.handleDeleteNotification}>{this.labels.HubButtonDelete}</button>
                    </div>
                    <div className="space-between">
                      <button className="button-secondary mr-2" disabled={isEditButtonDisabled} onClick={this.handleEditNotification}>{this.labels.HubButtonEdit}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    // mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(FacilityNotifications);
