import { Component } from "react";
import "../../styles/css/table.scss";
import "../../App.css";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { appContext } from "../../AppContext";

class NoRights extends Component {
  private labels = appContext().labels;


  render() {
    const { lightModeTheme }: any = this.props;

    return (
      <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
        <Sidebar {...this.props} />
        <div className="rightPanel">
          <div className="rightPanel-main-content">
          <div className="redirectContainer">
          <div className="title titleCenter">{this.labels.HubLabelNoRights}</div>
          </div>
            
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProviders,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(NoRights);
