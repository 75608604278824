import upArrow from "../../../../src/assets/images/arrow/Up Green.svg";
import down from "../../../../src/assets/images/arrow/Down Red.svg";
import rightArrow from "../../../../src/assets/images/arrow/Flat.svg";
import { ITrendChartModel } from "../models/ITrendChartModel";
import Grid from "@mui/material/Grid";

const TrendChart = (props: ITrendChartModel) => {
  const { percentage, fontSize, arrowSize, showPercentage } = props;

  return (
    <Grid container spacing={5} className="ai-center">
    {showPercentage ? <Grid item xs={3} sm={3} md={3} className="content-arrow arrow-top" style={{ fontSize: `${fontSize}em` }}>
      {percentage}%
    </Grid>: null}
    
    <Grid item xs={3} sm={3} md={3}>
      <img
        src={percentage > 0 ? upArrow : percentage < 0 ? down : rightArrow}
        alt={"arrow"}
        height={arrowSize}
      />
    </Grid>
  </Grid>
  )
}

export default TrendChart;
