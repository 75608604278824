import { Component } from "react"; 
import "../../styles/css/table.scss";
import "../../App.css";
import Header, { IProps as IHeaderProps } from "../../Components/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { appContext } from "../../AppContext";

class WorkProgress extends Component {
  private labels = appContext().labels;
  state = {
  };
  
  componentDidMount() {
    
  }


  render() {
    const { lightModeTheme }: any = this.props;
    
    return (
      <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
        <Sidebar {...this.props} />
        <div className="rightPanel">
          <Header {...this.props as unknown as IHeaderProps} />
          <div className="rightPanel-main-content">
            <h1>Work In Progress</h1>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProviders,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(WorkProgress);
