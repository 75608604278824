import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { SpacesFilter } from "./SpaceRepository";

export class GetV2SpacesEndpoint implements IGetV2SpacesEndpoint
{
    public async execute<TSpace>(tSpace: new() => TSpace, nodeId: number, top: number, filter?: SpacesFilter): Promise<TSpace[]>
    {
        try
        {
            const query =
                `showDebugStats=false&` +
                `$top=${top}&` +
                `$filter=${filter?.toODataString() ?? ""}&` +
                `$select=${ApiHelper.toODataSelect(tSpace)}`;

            const response = await axios.get<TSpace[]>(`${apis.allApiv2}${nodeId}/Spaces/search?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV2SpacesEndpoint
{
    execute<TSpace>(type: new() => TSpace, nodeId: number, top: number, filter?: SpacesFilter): Promise<TSpace[]>;
}
