import { Component } from "react";
import { appContext } from "../AppContext";
import IbssDialog from "./uicomponents/IbssDialog";
import IbssButtonRedo from "./uicomponents/IbssButton";

export default class AlertModal extends Component<IProps>
{
    private labels = appContext().labels;
    
    public render(): JSX.Element
    {
        const { show, handleModal, modalMessage, messageIsHtml, modalTitle } = this.props;
        const showModal = (show && (modalMessage ?? "") != "");

        return (
            <>
                <IbssDialog
                    open={showModal}
                    onClose={() => handleModal()}
                    content = 
                    {
                        <>
                            {messageIsHtml && <span dangerouslySetInnerHTML={{ __html: modalMessage }}></span>}
                            {!messageIsHtml && <span>{modalMessage}</span>}
                        </>
                    }
                    header = {modalTitle}
                    footer = 
                    {
                        <>
                            <div className="d-flex justify-content-center w-100">
                                <IbssButtonRedo color="primary" variant="contained"
                                        onClick={() => handleModal()}
                                    >
                                        {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </div>
                        </>
                    }
                    fullWidth
                />
            </>
        );
    }
}

export class AlertModalController
{
    public changed?: ((show: boolean, title: string, message: string, messageIsHtml: boolean) => Promise<void>);
    private onClose?: () => void;

    public show(title: string, message: string, onClose?: () => void, options?: { messageIsHtml?: boolean }): Promise<void>
    {
        if (!message || !this.changed)
        {
            return Promise.resolve();
        }
        this.onClose = onClose;
        return this.changed(true, title, message, (options?.messageIsHtml ?? false));
    }

    public async hide(): Promise<void>
    {
        try
        {
            if (!this.changed)
            {
                return Promise.resolve();
            }

            await this.changed(false, "", "", false);
            if (this.onClose)
            {
                this.onClose();
            }
        }
        finally
        {
            this.onClose = undefined;
        }
    }
}

export interface IProps
{
    show: boolean;
    modalTitle: string;
    modalMessage: string;
    messageIsHtml: boolean;
    handleModal: () => void;
}
