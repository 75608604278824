import { UnregisterCallback } from "history";
import { DateTime } from "luxon";
import { Component } from "react"
import { match, matchPath, RouteComponentProps } from "react-router";
import { appContext } from "../AppContext";

export class IbssComponent<TProps, TState> extends Component<TProps, TState>
{
    private get _appState() { return appContext().state; }
    private unregisterHistoryChanged: (UnregisterCallback | null) = null;

    public get pageTitle(): string
    {
        return this._appState.pageTitle;
    }

    public set pageTitle(value: string)
    {
        this._appState.set({ pageTitle: value });
    }

    public setStateAsync<K extends keyof TState>(state: ((prevState: Readonly<TState>, props: Readonly<TProps>) => (Pick<TState, K> | TState | null)) | (Pick<TState, K> | TState | null)): Promise<void>
    {
        return new Promise(resolve =>
        {
            this.setState(state, () => resolve());
        });
    }

    public componentWillUnmount(): void
    {
        this.pageTitle = "";
        this.unregisterHistoryChanged?.();
    }

    public onBuildingIdChanged<TMatchParams>(getBuildingIdMatchParam: (matchParams: TMatchParams) => string, buildingIdChanged: (buildingId: number, changeReason: BuildingChangeReason) => Promise<void>): void
    {
        const props = this.props as unknown as RouteComponentProps;

        const getBuildingIdMatch = (): (number | null) =>
        {
            const match = matchPath(window.location.pathname, { path: props.match.path });
            if (match == null)
            {
                return null;
            }
            const buildingId = parseInt(getBuildingIdMatchParam(match.params as TMatchParams));
            return (isNaN(buildingId) ? null : buildingId);
        }

        // handle building selector change
        const ref = this._appState.subscribe(this, async i =>
        {
            const buildingIdMatch = getBuildingIdMatch();
            if (i.buildingId == null || i.buildingId == buildingIdMatch)
            {
                return;
            }
            await buildingIdChanged(i.buildingId, "BuildingSelectorChanged");
        }, false);

        // update building drop-down when URL changed, then invoke event handler
        if (props.history != null)
        {
            this.unregisterHistoryChanged = props.history.listen(async () =>
            {
                const buildingIdMatch = getBuildingIdMatch();
                if (buildingIdMatch == null || this._appState.buildingId == buildingIdMatch)
                {
                    return;
                }

                await this._appState.set({ buildingId: buildingIdMatch });
                await buildingIdChanged(buildingIdMatch, "UrlChanged");
            });
        }
    }
}

export type BuildingChangeReason = "BuildingSelectorChanged" | "UrlChanged";
