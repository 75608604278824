import { Component } from "react";
import "../../../styles/css/table.scss";
import "../../../App.css";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import { getAirStatus } from "../../../Common/Helper";
import { Link } from "react-router-dom";
import Spinner from "../../../Components/Spinner";
import FormPanelInfo from "../../../Components/FormPanelInfo";
import InputTextBox from "../../../Components/uicomponents/InputTextBox";
import { appContext } from "../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../app/constants";
import { DateTime } from "luxon";


class FacilityZoneDetails extends Component<any, State> {

  private labels = appContext().labels;
  private session = appContext().sessionStorageProvider;

  constructor(props: any) {
    super(props);
    this.state = new State();
  }


  async componentDidMount() {
    const { history, match, dispatch, facilityZoneDtl }: any = this.props;
    const { zoneId } = match.params;
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsZones});
    if (facilityZoneDtl != undefined && Object.keys(facilityZoneDtl).length > 0) {
      await this.setState({
        facilityZoneDtlData: facilityZoneDtl
      });
    } else {
      history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}`)
    }

    this.handleGetZoneDtlData();
  }

  handleGetZoneDtlData = async () => {

    this.setState({ isLoading: true })
    this.makeFormFields();
    this.setState({
      isLoading: false,
    })


  }
  makeFormFields = () => {
    let arr = [
      {
        name: 'Summary_Date',
        label: this.labels.HubLabelDate,
        value: this.state.facilityZoneDtlData.Summary_Date_Local,
        readonly: true
      },
      
      {
        name: 'IAQ_Status',
        label: this.labels.HubLabelStatus,
        value: getAirStatus(this.state.facilityZoneDtlData.IAQ_Status),
        readonly: true
      },
      {
        name: 'Env_Temperature',
        label: this.labels.HubLabelTemperature,
        value: `${this.state.facilityZoneDtlData.Env_Temperature}\u00b0C`,
        readonly: true
      },
      {
        name: 'Env_Humidity',
        label: this.labels.HubLabelHumidity,
        value: `${this.state.facilityZoneDtlData.Env_Humidity}%`,
        readonly: true
      },
      {
        name: 'Env_Sound_Level',
        label: this.labels.HubLabelNoise,
        value: `${this.state.facilityZoneDtlData.Env_Sound_Level} dB`,
        readonly: true
      },
      {
        name: 'Env_Zone_LastUpdated',
        label: this.labels.HubLabelLastUpdate,
        value: DateTime.fromISO(this.state.facilityZoneDtlData.Env_Zone_LastUpdated).toFormat("dd/MM/yyyy HH:mm"),
        readonly: true
      },
    ];
    this.setState({ isLoading: false, formFieldsData: arr })
  }
  

  openHistoricalData = () => {
    const { history, dispatch }: any = this.props;
    const startDate = DateTime.local().minus({ days: 7 }).toFormat("yyyy-MM-dd");
    const endDate = DateTime.local().toFormat("yyyy-MM-dd");
    dispatch({ type: "UPDATE_CURRENT_ENV_ZONE_NAME", currentEnvZoneName: this.state.facilityZoneDtlData.Env_Zone_Name });
    history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}/${this.state.facilityZoneDtlData.Env_Zone_Id}/historical?StartDate=${startDate}&EndDate=${endDate} `)
  }

  render() {
    return (
      <>
            <div className="rightPanel-main-content-form">
              <div className="form-panel">
                {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                <FormPanelInfo
                  formHeading={this.state.facilityZoneDtlData.Env_Zone_Name + ' - ' + this.state.facilityZoneDtlData.Summary_Date_Local}
                  iconSrc="/images/Sidebar_Icons/Light_theme/Zone.svg"
                />
                <form className="form-panel-form">
                  {this.state.formFieldsData.map((obj: any, ind: number) => {
                    return (
                      <div className="row" key={ind}>
                        <InputTextBox {...obj} />
                      </div>
                    )
                  })}
                </form>
              </div> 
              <div className="rightPanel-main-button-group">
              <div className="d-flex justify-content-end w-100">              
                  <button className="button-primary" style={{ cursor: "pointer" }} onClick={() => this.openHistoricalData()}>{this.labels.HubButtonBack}</button> 
              </div>
              </div>                
            </div>
      </>
    );
  }
}

class State {

  public isLoading = false;
  public formFieldsData: any = [];
  public facilityZoneDtlData: any = {};
}


const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
    facilityZoneDtl: state.facilityZoneDtl

  };
};

export default connect(mapStateToProps)(FacilityZoneDetails);

