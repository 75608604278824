import { appContext } from "../AppContext";
import { IUserPreferences } from "../Providers.Api/UserPreferenceRepository";

export class UserPreferencesService implements IUserPreferencesService
{
    private api = appContext().apiClient;
    private local = appContext().localStorageProvider;

    public async update(userPreferences: IUserPreferences): Promise<IUserPreferences>
    {
        userPreferences = await this.api.userPreferences.updateUserPreferences(userPreferences);
        this.local.setUserPreferences(userPreferences);
        return userPreferences;
    }
}

export interface IUserPreferencesService
{
    update(userPreferences: IUserPreferences): Promise<IUserPreferences>;
}
