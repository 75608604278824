import AnalyticsRow from "../../Components/uicomponents/Analytics/AnalyticsRow";
import { RootStateOrAny, useSelector } from "react-redux";
import Alert from '../../Components/Alert/Alert';
import { spaceName } from '../../Common/Helper';
import { spaceAnalyticsTable } from '../../Components/uicomponents/models/IAnalyticsModel'

const SpaceAnalyticsTableDash = (props: {spaceAnalytics: spaceAnalyticsTable[], occupanyDiff: number, occupancyVal: number | undefined}) => {
  const { spaceAnalytics, occupanyDiff, occupancyVal } = props;
 const {
    onelensDateBuilding,
  } = useSelector((state: RootStateOrAny) => ({
    onelensDateBuilding: state.onelensDateBuilding.nodeLevelName
  }));

  return (
    <>
    {/* Alert response 0 */}
    {spaceAnalytics.length === 0? <Alert key="noResults" title={"There are currently no spaces available"} text={`Based on your search criteria we've found no spaces available, please adjust your criteria and try again.`} />
     :
     null
    }

    {/* Datatable response render */}
     {spaceAnalytics.map(({Node_Id,Space_Class,Space_Name,Space_Id,Space_Type, Space_Type_Label, Period_Current_Space_Value, Period_Current_Max_Occ_Value,Period_Current_Avg_Occ_Value, Period_Change_Space_Value, Period_Current_Capacity_Value, Period_Change_Capacity_Value}) => (
      <>
      <AnalyticsRow
           Node_Id={Node_Id}
           Space_Class={Space_Class}
           Space_Type={Space_Type}
           Period_Current_Capacity_Value={Period_Current_Capacity_Value}
           name={spaceName(Space_Name, Space_Type_Label, onelensDateBuilding, onelensDateBuilding.buildingNodeId)}
           spaceId={Space_Id}
           spaceUtilization={Math.round(Period_Current_Space_Value)}
           spaceChange={Math.round(Period_Change_Space_Value)}
           maxOccupancy={Math.round(Period_Current_Max_Occ_Value)}
           avgOccupancy={Math.round(Period_Current_Avg_Occ_Value)}
           capacityUtilization={Math.round(Period_Current_Capacity_Value)}
           capacityChange={Math.round(Period_Change_Capacity_Value)}
           occupanyDiff={occupanyDiff}
           occupancyVal={occupancyVal}
           />
      </>
    ))}
    </>
  );
};

export default SpaceAnalyticsTableDash;
