import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../App.css";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect } from "react-redux";
import { appContext } from "../../AppContext";
 
class AccessDenied extends Component {
  private labels = appContext().labels;

  render() {
    
    return (
          <div className="content">
            <div className="page-title mb-20">
              <h1> {this.labels.HubLabelaccessDeniedLabel}</h1>
            </div>
            <div className="row">
              <div className="col-12 card ml-0">
                <h3>{this.labels.HubLabelaccessDenied}</h3>
              </div>
            </div>
          </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
  };
};

export default connect(mapStateToProps)(AccessDenied);
