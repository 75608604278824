import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../App.css";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Submenu from "../../Components/SubMenu";
import { connect } from "react-redux";
import { appContext } from "../../AppContext";

class Blank extends Component
{
    private get labels() { return appContext().labels; }

    render()
    {
        const { currentPageTitle }: any = this.props;
        return (
            <>
                <Submenu {...this.props} />
                <div className="content">
                    <div className="page-title mb-20">
                        <div className="breadcrumbs">
                            {" "} {this.labels.HubMenuSecurity} {" > "} {currentPageTitle}
                        </div>
                        <h1> {currentPageTitle}</h1>
                    </div>
                    <div className="row">
                        <div className="col-6 card   ml-0"></div>
                        <div className="col-6 card mr-0"></div>
                    </div>
                    <div className="row">
                        <div className="col-12 card   ml-0 mr-0"></div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
    };
};

export default connect(mapStateToProps)(Blank);
