import { Component } from "react";
import Header, { IProps as IHeaderProps } from "../../Components/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { connect } from "react-redux";
import { appContext } from "../../AppContext";


class Unauthorised extends Component
{
    private labels = appContext().labels;

    render()
    {
        const { lightModeTheme }: any = this.props;

        return (
            <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
                <Sidebar {...this.props} />
                <div className="rightPanel">
                    <Header {...this.props as unknown as IHeaderProps} />
                    <div className="rightPanel-main-content">
                        <div className="redirectContainer">
                            <div className="title titleCenter">{this.labels.HubLabelUnauthorisedMessage}</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(Unauthorised);
