export default class Guid
{
	private value = "";

	constructor()
	{
		this.generate();
	}

	public static new(): Guid
	{
		return new Guid();
	}

	private generate()
	{
		this.value = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/x/g, char => (Math.random() * 16 | 0).toString(16));
	}

	public toString(format = "D"): string
	{
		switch (format)
		{
			case "D":
				return this.value;
			case "N":
				return this.value.replace(/-/g, "");
			default:
				throw new Error(`"The format '${format}' is not supported`);
		}
	}
}