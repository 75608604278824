import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IUserPreferences } from "../UserPreferenceRepository";

export class AssignBookingPolicyToSpacesEndpoint implements IAssignBookingPolicyToSpacesEndpoint
{
    public async execute(nodeId: number, bookingPolicyId: string, spaceIds: string[]): Promise<void>
    {
        try
        {
            const data = { spaceIds: spaceIds };
            const response: AxiosResponse<IUserPreferences> = await axios.patch(`${apis.dataEntryApiV2}/${nodeId}/BookingPolicies/${bookingPolicyId}/Apply`, data);
            if (response.status !== 200)
            {
                throw response;
            }
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IAssignBookingPolicyToSpacesEndpoint
{
    execute(nodeId: number, bookingPolicyId: string, spaceIds: string[]): Promise<void>;
}
